import * as jose from 'jose';
let initialStateDB = JSON.parse(localStorage.getItem('Auth'));

export const AuthState =initialStateDB ||  {
	Token: null,
	Sper: null,
	TokenPayload: null,
	ITSME_Nonce: null,
	ITSME_State: null,
	ITSME_Action: null,
	ITSME_Origine: null,
	TokenFromMyChrh: null,
	Nom: null,
	Prenom: null,
	Alias: null
}

export const AuthReducer = (AuthState, action) => {
	switch (action.type) {
		case 'LOGIN_FROM_MYCHRH':
			{
				let payloadJwt = jose.decodeJwt(action.payload);
				let newAuthState = {
					...AuthState,
					Token: action.payload,
					Sper: payloadJwt.sper,
					TokenPayload: payloadJwt,
					ITSME_Nonce: null,
					ITSME_State: null,
					ITSME_Action: null,
					ITSME_Origine: null,
					TokenFromMyChrh: true,
					Nom: null,
					Prenom: null,
					Alias: null
				};
				localStorage.setItem('Auth', JSON.stringify(newAuthState));
				return newAuthState;
            }
		case 'LOGIN_SUCCESS':
			{
				let payloadJwt = jose.decodeJwt(action.payload);
				let newAuthState = {
					...AuthState,
					Token: action.payload,
					Sper: payloadJwt.sper,
					TokenPayload: payloadJwt,
					ITSME_Nonce: null,
					ITSME_State: null,
					ITSME_Action: null,
					ITSME_Origine: null,
					TokenFromMyChrh: false,
					Nom: payloadJwt.nom,
					Prenom: payloadJwt.prenom,
					Alias: payloadJwt.alias
				};
				localStorage.setItem('Auth', JSON.stringify( newAuthState));
				return newAuthState;
			}
		case 'ITSME_CONNECTING':
			{
				let newAuthState = {
					...AuthState,
					ITSME_State: action.payload.state,
					ITSME_Nonce: action.payload.nonce,
					ITSME_Action: action.payload.action,
					ITSME_Origine:action.payload.origine
				};
				localStorage.setItem('Auth', JSON.stringify( newAuthState));
				return newAuthState;
            }
		case 'LOGOUT':
			{
				let newAuthState = {
					...AuthState,
					Token: null,
					Sper: null,
					TokenPayload: null,
					ITSME_Nonce: null,
					ITSME_State: null,
					ITSME_Action: null,
					TokenFromMyChrh: null,
					Nom: null,
					Prenom: null,
					Alias: null
				};
				localStorage.setItem('Auth', JSON.stringify(newAuthState));
				return newAuthState;
			}
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};