import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Accordion, Table } from 'react-bootstrap';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import LoadingSpinner from "../LoadingSpinner.js";
import { Login } from "./Login.js";
import { BoutonItsme } from './BoutonItsme.js';
import logo from '../logo_my_chrh_144.png';

export function RegisterOrLogin() {
    const [loading, setLoading] = useState(false);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();


    useEffect(() => {

    }, []);
    //[] = liste de variable qui declenche une execution de la fonction lorsque que leur valeur change
    //avec une liste vide [], use effect ne s'execute qu'une fois au chargement de la page


    return (
        <div>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&
                <div>
                    <Container fluid style={{ paddingTop: 15 }} >
                        <Row className="justify-content-center">
                            <Col xs="12" sm="11" lg="10" xl="9" >
                                <Container fluid style={{ paddingTop: 15 }}>
                                    <Row className="justify-content-center" >
                                        <Col xs="12" sm="6" className="text-center mb-5">
                                            <img src={logo} alt='My chr de Huy' />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs="12" sm="6" >
                                            <div className="card ">
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ marginLeft: 35 }}>Connexion</h5>
                                                    <Login />
                                                    <hr />
                                                    <div className="text-center">
                                                        <BoutonItsme />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    );

}