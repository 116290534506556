import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useForm from "../FormValidation/useForm.js";
import InputValidation from "../FormValidation/InputValidation.js";
import InputValidationPassword from "../FormValidation/InputValidationPassword.js";

export function NouveauPass() {
  const textInput = useRef(null);

  const history = useNavigate();
  const InitialValues = { Alias: "", newPass: "", newPass2: "" };
  const ValidationSchema = yup.object().shape({
    Alias: yup.string().required("Obligatoire"),
    newPass: yup
      .string()
      .trim()
      .required("Obligatoire")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
        "Doit contenir 8 caractères et au moins une majuscule, une minuscule et un chiffre"
      ),
    newPass2: yup
      .string()
      .trim()
      .required("Obligatoire")
      .oneOf([yup.ref("newPass"), null], "Les mots de passes sont différents"),
  });

  //Recuperer les params querystring
  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const annuler = () => {
    history("/login");
  };

  const ChangePass = () => {
    const jssha = require("js-sha256");

    function hash(input) {
      return jssha.sha256(input).toUpperCase();
    }

    const hashResult = hash(formValidation.values.newPass);

    const valeurs = {
      Id: getParameterByName("Code"),
      NewPass: hashResult,
      Alias: formValidation.values.Alias,
    };
    fetch("donnees/NouveauPass/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(valeurs),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            alert(
              "Erreur lors de la procédure, re-demandez un nouveau mot de passe"
            );
          } else if (response.status === 500) {
            //dispatch({ type: 'LOGIN_ERROR' });
            alert("Erreur interne, recommencez la procédure.");
          }
          throw new Error(response.status);
        } else {
          alert("Mot de passe changé avec succès !");
          history("/login/");
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        if (error != null && error.response != null && error.status != null) {
          if (error.response.status === 403) {
            //dispatch({ type: 'LOGIN_ERROR' });
            alert(
              "Erreur lors de la procédure, re-demandez un nouveau mot de passe"
            );
          } else if (error.response.status === 500) {
            //dispatch({ type: 'LOGIN_ERROR' });
            alert("Erreur interne, recommencez la procédure.");
          }
        }
      });
  };

  const submit = () => {
    ChangePass();
  };
  const formValidation = useForm(InitialValues, submit, ValidationSchema);

  useEffect(() => {
    //Vérif si l'id est passé
    var id = getParameterByName("Code"); //Appel depuis le site de l'hopital
    if (!id) {
      alert(
        "Veuillez ouvrir cette page à partir du lien que vous avez recu dans le mail."
      );
      history("/login/");
    }

    textInput.current.focus();
  }, []);

  return (
    <div>
      <h1>Nouveau mot de passe</h1>
      <Container fluid style={{ paddingTop: 15 }}>
        <Row>
          <Col xs="12" sm="11" lg="10" xl="9">
            <div id="ListeFactures" className="card mb-2">
              <div className="card-header ">
                <strong>Définissez votre nouveau mot de passe</strong>
              </div>
              <div className="card-block mb-2">
                <Row className="justify-content-center">
                  <form
                    className="needs-validation"
                    novalidate
                    className="col-sm-10"
                  >
                    <div className="row p-2  g-3 align-items-center">
                      <div className="col-lg-3">
                        <label htmlFor="Alias">Nom d'utilisateur :</label>
                      </div>
                      <div className="col-lg-9">
                        <InputValidation
                          autofocus
                          nom="Alias"
                          validation={formValidation}
                          tabIndex="1"
                          reference={textInput}
                        />
                      </div>
                    </div>
                    <div className="row p-2  g-3 align-items-center">
                      <div className="col-lg-3">
                        <label htmlFor="newPass">Mot de passe :</label>
                      </div>
                      <div className="col-lg-9">
                        <InputValidationPassword
                          nom="newPass"
                          validation={formValidation}
                          tabIndex="2"
                        />
                      </div>
                    </div>
                    <div className="row p-2  g-3 align-items-center">
                      <div className="col-lg-3">
                        <label htmlFor="newPass2">Mot de passe :</label>
                      </div>
                      <div className="col-lg-9">
                        <InputValidationPassword
                          nom="newPass2"
                          validation={formValidation}
                          tabIndex="3"
                        />
                      </div>
                    </div>
                    <div className="row p-2  g-3">
                      <div className="col-lg-12  d-flex justify-content-end">
                        <Button
                          type="button"
                          onClick={annuler}
                          variant="secondary"
                          tabIndex="4"
                        >
                          Annuler
                        </Button>{" "}
                        &nbsp;&nbsp;
                        <Button
                          type="button"
                          onClick={formValidation.handleSubmit}
                          tabIndex="5"
                        >
                          Modifier le mot de passe
                        </Button>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
