import React, { useState, useEffect } from 'react'
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'moment';
import BoutonDownload from './BoutonDownload.js'
import { useNavigate } from "react-router-dom";
import './Documents.css';
import LoadingSpinner from "../LoadingSpinner.js";

export function Documents() {
    const [loading, setLoading] = useState(false);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            setLoading(true);


            fetch('donnees/documents/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    //setListeFactures(data);
                    setData(data);
                    setLoading(false);

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, []);

    return (
        <div>
            <h1  >Mes documents</h1>
            {loading &&
                <LoadingSpinner />
            }
            {
                !loading &&
                <div>
                    <Container fluid style={{ paddingTop: 15 }} className="mb-2">

                        <Row>
                            <Col xs="12" sm="11" lg="10" xl="9" className="mb-5">
                                <h5 className="mb-2" >Documents à télécharger</h5>

                                {(!data || data.length <= 0) &&
                                    <div>Aucun document disponible </div>
                                }
                                {(data && data.length > 0) &&
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover ">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Document</th>
                                                    <th scope="col">Medecin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item) => {
                                                    var date = Moment(item.DU).format('DD/MM/YYYY');
                                                    var dateFichier = Moment(item.DU).format('YYYY-MM-DD');
                                                    return (
                                                        <tr key={item.ID_DOC}>
                                                            <td><BoutonDownload id={item.ID_DOC} source={item.TABLE_SOURCE} nomFichier={dateFichier + '_' + item.DESC_DOC} /></td>
                                                            <td>{date}</td>
                                                            <td scope="row">{item.TYPE_DOC}</td>
                                                            <td>{item.SPRR_LIB}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    )
}
