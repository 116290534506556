import React from 'react'
import { AliasPerdu } from './AliasPerdu.js';

export function PassOublie() {
    return (
        <AliasPerdu passPerdu={true} />
    );
}



