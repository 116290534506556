import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";

function BoutonDownload({ id,source,nomFichier }) {
    const [loading, setLoading] = useState(false);

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();


    const openDoc = () => {
    console.log('Open Doc');

    var bearerToken = AuthState.Token;
    if (bearerToken == null)
    {
        //Pas de token, on redirige vers le login
        //alert("Vous n'êtes pas connecté")
        AuthDispatch({ type: 'LOGOUT' });
        history("/login/");
    }

    setLoading(true);






    fetch('donnees/document/', {
    method: 'POST',
            headers:
        {
            'Authorization': `Bearer ${ bearerToken}`,
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json' },
            body: JSON.stringify({ id: id, source:source })
        })
        .then(response => {
                 if (!response.ok)
                 {
                     if (response.status === 403) {

                         //dispatch({ type: 'LOGIN_ERROR' });
                         alert("La session a expiré.")
                         AuthDispatch({ type: 'LOGOUT' });
                         history("/login/");
                     }
                     else if (response.status === 404) {

                         //dispatch({ type: 'LOGIN_ERROR' });
                         alert("Erreur de téléchargement du document. Veuillez nous en excuser...")
                         
                     }
                     throw new Error(response.status);
                 }
                 else return response.json();
             })
        .then((data) => {
                var byteArray = new Uint8Array(data);
                var blob = new Blob([byteArray], { type: 'application/pdf', filename: 'Document.pdf' });
//console.log(blob)

//Build a URL from the file
const fileURL = URL.createObjectURL(blob);
//Response.AddHeader("content-disposition", string.Format( "attachment;filename=""Facture_CHRH_{0}.pdf""", numFacture));

//Log consultation facture
fetch("donnees/LogConsultationDocument/",
                    {
method: 'POST',
                        headers:
    {
        'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${ bearerToken}`
                        },
                        body: JSON.stringify({ id: id,source:source})
                    })


                //Ouvre le document pdf dans le navigateur
                //window.open(fileURL);

                const url = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = url;
link.setAttribute('download',nomFichier); //or any other extension
document.body.appendChild(link);
link.click();


setLoading(false);
            })
            .catch(error => {
                      console.log('error: ' + error);
                      setLoading(false);
                      if (error != null && error.response != null && error.status != null)
                      {
                          if (error.response.status === 403)
                          {

                              //dispatch({ type: 'LOGIN_ERROR' });
                              alert('Nom ou mot de passe incorrect')
                          }
                          else if (error.response.status === 401)
                          {

                              //dispatch({ type: 'LOGIN_ERROR' });
                              alert('Trop de tentatives de connexion. Essayez dans 15 min.')
                          }
                      }
                  });



    }

    return (
        < Button variant = "outline-secondary" onClick ={ openDoc}
disabled ={ loading ? `disabled` : ``} >
            {
    loading ? (
                <>
                    < Spinner animation = "border" size = "sm" >
                        < span className = "sr-only" > Loading...</ span >
                    </ Spinner >
                    &nbsp; Téléchargement...
                </>
            )
                : (

                    <>
                        < FontAwesomeIcon icon ={ faDownload}>

                        </ FontAwesomeIcon >
                    &nbsp; Pdf
                    </>
                )
            }
        </ Button >
    );


}

export default BoutonDownload