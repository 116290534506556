import React, { useState, useEffect } from 'react';
import { Row, Spinner, Button, Card, Accordion, Table } from 'react-bootstrap';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';
import InputValidationPassword from '../FormValidation/InputValidationPassword.js';
import { useNavigate } from "react-router-dom";


export function Login() {
    const [loading, setLoading] = useState(false);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    
    const InitialValues = { Utilisateur: '', Password: '' };
    const ValidationSchema = yup.object().shape({
        Utilisateur: yup.string()
            .required('Obligatoire'),
        Password: yup.string()
            .required('Obligatoire')
            .min(6, 'Trop court')

    });

    const Login = () => {
        const jssha = require('js-sha256')
        setLoading(true);
        function hash(input) {
            return jssha.sha256(input).toUpperCase();
        }

        const hashResult = hash(formValidation.values.Password)

        const valeurs = {
            Utilisateur: formValidation.values.Utilisateur,
            Password: hashResult
        }
        
        fetch("donnees/Login/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(valeurs)
            })
            .then(response => {
                setLoading(false);
                if (!response.ok) {
                    if (response.status === 403) {

                        //dispatch({ type: 'LOGIN_ERROR' });
                        alert("Nom ou mot de passe incorrecte !")
                    }
                    else if (response.status === 401) {

                        //dispatch({ type: 'LOGIN_ERROR' });
                        alert('Trop de tentatives de connexion. Essayez dans 15 min.')
                    }
                    throw new Error(response.status);
                }
                else return response.json();
            })
            .then((data) => {
                AuthDispatch({ type: 'LOGIN_SUCCESS', payload: data.token });
                //S'il y a un param de redirection, l'utiliser sinon, on redirige vers RDV
                let redirect = getParameterByName('redirect');
                if (redirect != null) {
                    history("/" + redirect + "/");
                }
                else
                    history("/rendezvous/");
            })
            .catch(error => {
                setLoading(false);
                console.log('error: ' + error);
            });

    }

    const formValidation = useForm(
        InitialValues,
        Login,
        ValidationSchema,
    )

    const identifiantOublie = () => {
        history("/identifiant-oublie");
    }
    const passOublie = () => {
        history("/pass-oublie");
    }

    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    return (
        <Row className="justify-content-center">
            <form className="needs-validation" noValidate className="col-sm-10" >
                <div >
                    <label htmlFor="Utilisateur" className="col-form-label">Utilisateur :</label>
                    <div >
                        <InputValidation nom={'Utilisateur'} validation={formValidation} />
                        <small className="text-muted" onClick={identifiantOublie} style={{ cursor: 'pointer' }}>
                            Nom d'utilisateur oublié ?
                        </small>
                    </div>
                </div>
                <div >
                    <label htmlFor="Password" className="col-form-label">Mot de passe</label>
                    <div >
                        <InputValidationPassword nom={'Password'} validation={formValidation} />
                        <small className="text-muted" onClick={passOublie} style={{ cursor: 'pointer' }}>Mot de passe oublié ?</small>
                    </div>
                </div>
                <div>
                    {loading ? (<Button type="button" disabled >
                        <Spinner animation="border" size="sm" >
                            <span className="sr-only">Connexion...</span>
                        </Spinner>
                        &nbsp; Connexion...
                    </Button>) : (<Button type="button" onClick={formValidation.handleSubmit} >Connexion</Button>)
                    }
                    
                </div>
            </form>
        </Row>
    );

}