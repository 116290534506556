import React from 'react'
import { useRDVState, useRDVDispatch } from '../../Context/context.js';

function ChoixJours() {
    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();

    const handleSingleCheck = e => {
        RDVDispatch({ type: 'SET_JOURS', payload: { ...RDVState.Jours, [e.target.value]: e.target.checked } });
    };

    const jours = [
        { "cle": 1, "valeur": 'Lundi' },
        { "cle": 2, "valeur": 'Mardi' },
        { "cle": 4, "valeur": 'Mercredi' },
        { "cle": 8, "valeur": 'Jeudi' },
        { "cle": 16, "valeur": 'Vendredi' },
        { "cle": 32, "valeur": 'Samedi' },
        { "cle": 64, "valeur": 'Dimanche' }];
    return (
        <div>
            { jours.map((jour) => {
                return (
                    <div className="form-check" key={jour.cle}>
                        <input className="form-check-input" type="checkbox" value={jour.cle} checked={RDVState.Jours[jour.cle]}
                            onChange={handleSingleCheck} id={`ChkJours_${jour.cle}`} />
                        <label className="form-check-label" htmlFor={`ChkJours_${jour.cle}`}>
                            {jour.valeur}
                        </label>
                    </div>
                )
            })}

        </div>

    )

}

export default ChoixJours