import React, {useState, useEffect, useRef } from 'react'
import { Container, Row, Col,  Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';

export function AliasPerdu({ passPerdu = false }) {
    const textInput = useRef(null);

    const history = useNavigate();
    const regexNiss = /^[0-9]{2}[.\-]{0,1}[0-9]{2}[.\-]{0,1}[0-9]{2}[.\-]{0,1}[0-9]{3}[.\-]{0,1}[0-9]{2}$/;
    const InitialValues = { Email: '', Niss: '' };
    const ValidationSchema = yup.object().shape({
        Email: yup.string()
            .email( 'Format incorrect.')
            .required('Obligatoire'),
        Niss: yup.string()
            .trim()
            .matches(regexNiss, 'Format attendu: 00.01.01-123.45')
            .required('Obligatoire')

    });

    const annuler = () => {
        history("/login");
    }

    const EnvoyerDemandeAlias = () => {
        const body = { Email: formValidation.values["Email"], Niss: formValidation.values["Niss"] };
        fetch("donnees/identifiant-oublie/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then((data) => {
                alert('Si les informations encodées sont correctes, vous allez recevoir votre identifiant par mail...');
                history("/login/");
            }).catch(function (error) {
                alert('Une erreur interne est survenue, veuillez recommencer plus tard. Merci.');
                history("/login/");
            });
    }

    const EnvoyerDemandePass = () => {
        const body = { Email: formValidation.values["Email"], Niss: formValidation.values["Niss"] };
        fetch("donnees/pass-oublie/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then((data) => {
                alert('Si les informations encodées sont correctes, vous allez recevoir votre demande par mail...');
                history("/login/");
            }).catch(function (error) {
                alert('Une erreur interne est survenue, veuillez recommencer plus tard. Merci.');
                history("/login/");
            });
    }

    const submit = () => {
        if (!passPerdu) {
            EnvoyerDemandeAlias();
        }
        else {
            EnvoyerDemandePass();
        }
    }
    const formValidation = useForm(
        InitialValues,
        submit,
        ValidationSchema,
    )

    useEffect(() => {
        textInput.current.focus();
    }, []);


    return (
        <div>
            {!passPerdu && <h1  >Identifiant oublié</h1>}
            {passPerdu && <h1  >Mot de passe oublié</h1>}
            <Container fluid style={{ paddingTop: 15 }} >
                <Row>

                    <Col xs="12" sm="11" lg="10" xl="9">
                        <div id="ListeFactures" className="card mb-2" >

                            <div className="card-header ">
                                {!passPerdu && <strong>Me renvoyer mon identifiant</strong>}
                                {passPerdu && <strong>Demander un nouveau mot de passe</strong>}
                                
                            </div>
                            <div className="card-block mb-2">
                                <Row className="justify-content-center">
                                    <form className="needs-validation" novalidate className="col-sm-10" >
                                        <div className="row p-2  g-3 align-items-center">
                                            <div className="col-lg-3">
                                                <label htmlFor="Email">Email :</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <InputValidation autofocus prepend="@" nom="Email" validation={formValidation} tabIndex="1" reference={textInput} />
                                            </div>
                                        </div>
                                        <div className="row p-2  g-3 align-items-center">
                                            <div className="col-lg-3">
                                                <label htmlFor="Niss" >Numéro national :</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <InputValidation nom="Niss" validation={formValidation} placeholder="00.01.01-123.45" tabIndex="2"/>
                                            </div>
                                        </div>
                                        <div className="row p-2  g-3">
                                            <div className="col-lg-12  d-flex justify-content-end">
                                                <Button type="button" onClick={annuler} variant="secondary" tabIndex="3">Annuler</Button> &nbsp;&nbsp;
                                                {!passPerdu &&
                                                    <Button type="button" onClick={formValidation.handleSubmit} tabIndex="4">Recevoir mon identifiant</Button>
                                                }
                                                {passPerdu &&
                                                    <Button type="button" onClick={formValidation.handleSubmit} tabIndex="4">Envoyer ma demande</Button>
                                                }
                                            </div>
                                        </div>
                                    </form>

                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}



