import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation';
import SelectValidation from '../FormValidation/SelectValidation';

export function PatientTemp({ affichePanelLogin }) {
    const textInput = useRef(null);
    const [year, setYear] = useState([]);

    useEffect(() => {
        fetch('rdvo/getoptionyear', {
            method: 'get'
        })
            .then(response => response.json())
            .then((data) => {
                setYear(data);
            });
        textInput.current.focus();
    }, []);

    function EmptyNumber(typeErrorMessage = "Entrez un nombre valide") {
        return yup.number()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value
                if (!originalValue || !originalValue.trim()) {
                    return null
                }
                // we return the invalid original value
                return originalValue
            })
            .nullable(true)
            .typeError(typeErrorMessage)
    }

    const InitialValues = {};
    const ValidationSchema = yup.object().shape({
        nom: yup.string()
            .required('Obligatoire'),
        prenom: yup.string()
            .required('Obligatoire'),
        sex: yup.string()
            .required('Obligatoire'),
        ddn_dd: yup.string()
            .required('Obligatoire'),
        ddn_mm: yup.string()
            .required('Obligatoire'),
        ddn_yyyy: yup.string()
            .required('Obligatoire'),
        tel: EmptyNumber('Entrez uniquement des chiffres')
            .integer('Entrez uniquement des chiffres')
            .test("", "Téléphone ou GSM obligatoire", function (value) {
                const { gsm } = this.parent;
                if (!gsm) return value != null
                return true
            }),
        gsm: EmptyNumber('Entrez uniquement des chiffres')
            .integer('Entrez uniquement des chiffres')
            .test("", "Téléphone ou GSM obligatoire", function (value) {
                const { tel } = this.parent;
                if (!tel) return value != null
                return true
            })
    });


    const CreatePatientTemp = () => {

        const valeurs = {
            nom: formValidation.values.nom,
            prenom: formValidation.values.prenom,
            sex: formValidation.values.sex,
            DteNais_dd: parseInt(formValidation.values.ddn_dd),
            DteNais_mm: parseInt(formValidation.values.ddn_mm),
            DteNais_yyyy: parseInt(formValidation.values.ddn_yyyy),
            Tel: formValidation.values.tel != "" ? parseFloat(formValidation.values.tel) : null,
            Gsm: formValidation.values.gsm != "" ? parseFloat(formValidation.values.gsm) : null,
        }

        fetch('rdvo/CreatePatientTemp/', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset:utf-8'
            },
            body: JSON.stringify(valeurs)
        })
            .then(response => response.json())
            .then((data) => {
                window.open("/rendezvous/?token=" + data.token);
                affichePanelLogin();
            });

    }

    const formValidation = useForm(InitialValues, CreatePatientTemp, ValidationSchema);

    return (
        <div id="rdv_panel_3" className="row">
            <div className="col-md-12">
                <form className="form-horizontal" method="post">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Veuillez enregistrer vos données</h3>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="nom" className="col-sm-3 control-label">Nom *</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <InputValidation nom="nom" placeholder="Nom" validation={formValidation} reference={textInput}></InputValidation>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="prenom" className="col-sm-3 control-label">Prénom *</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <InputValidation nom="prenom" placeholder="Prénom" validation={formValidation}></InputValidation>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="sex" className="col-sm-3 control-label">Sexe *</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <Row>
                                        <Col sm="4">
                                            <SelectValidation nom="sex" validation={formValidation}>
                                                <option value="">Sexe</option>
                                                <option value="M">Masculin</option>
                                                <option value="F">Féminin</option>
                                            </SelectValidation>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="col-sm-6">
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="rdv_ddn" className="col-sm-3 control-label">Date de naissance *</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <Row>
                                        <Col sm="4">
                                            <SelectValidation nom="ddn_dd" validation={formValidation} placeholder="Jour">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </SelectValidation>
                                        </Col>
                                        <Col sm="4">
                                            <SelectValidation nom="ddn_mm" validation={formValidation} placeholder="Mois">
                                                <option value="1">Janvier</option>
                                                <option value="2">Février</option>
                                                <option value="3">Mars</option>
                                                <option value="4">Avril</option>
                                                <option value="5">Mai</option>
                                                <option value="6">Juin</option>
                                                <option value="7">Juillet</option>
                                                <option value="8">Août</option>
                                                <option value="9">Septembre</option>
                                                <option value="10">Octobre</option>
                                                <option value="11">Novembre</option>
                                                <option value="12">Décembre</option>
                                            </SelectValidation>
                                        </Col>
                                        <Col sm="4">
                                            <SelectValidation nom="ddn_yyyy" validation={formValidation} placeholder="Année">
                                                {year && year.map((element) => {
                                                    return (<option value={element}>{element}</option>)
                                                })}
                                            </SelectValidation>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="rdv_tel" className="col-sm-3 control-label">Téléphone</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <InputValidation nom="tel" placeholder="Téléphone" validation={formValidation} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="rdv_gsm" className="col-sm-3 control-label">GSM</label>
                                <div className="col-sm-9" style={{ display: "inline-block" }}>
                                    <InputValidation nom="gsm" placeholder="GSM" validation={formValidation} />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <Button type="button" onClick={formValidation.handleSubmit}>Valider</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}