import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import {  AuthProvider } from '../Context/context.js';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
      return (
          <div>
              <AuthProvider>
                  <NavMenu />
                  <Container>
                      {this.props.children}
                  </Container>
              </AuthProvider>
          </div>
      );
  }
}
