import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useAuthState, useAuthDispatch } from "../../Context/context.js";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useForm from "../FormValidation/useForm.js";
import InputValidation from "../FormValidation/InputValidation.js";
import InputValidationPassword from "../FormValidation/InputValidationPassword.js";

function ChangePass() {
  const [loading, setLoading] = useState(false);
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();
  const textInput = useRef(null);

  const history = useNavigate();
  const InitialValues = { pass: "", newPass: "", newPass2: "" };
  const ValidationSchema = yup.object().shape({
    pass: yup.string().required("Obligatoire"),
    newPass: yup
      .string()
      .trim()
      .required("Obligatoire")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
        "Doit contenir 8 caractères et au moins une majuscule, une minuscule et un chiffre"
      ),
    newPass2: yup
      .string()
      .trim()
      .required("Obligatoire")
      .oneOf([yup.ref("newPass"), null], "Les mots de passes sont différents"),
  });

  const saveData = () => {
    const jssha = require("js-sha256");

    function hash(input) {
      return jssha.sha256(input).toUpperCase();
    }

    const hashnewPass = hash(formValidation.values.newPass);
    const hashpass = hash(formValidation.values.pass);

    var bearerToken = AuthState.Token;
    if (bearerToken != null) {
      const valeurs = {
        Pass: hashpass,
        NewPass: hashnewPass,
      };
      setLoading(true);

      //Sauvegarde Itsme
      fetch("donnees/ChangeMotDePasse/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(valeurs),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 403) {
              //dispatch({ type: 'LOGIN_ERROR' });
              alert("La session a expiré.");
              AuthDispatch({ type: "LOGOUT" });
              history("/login/");
            }
            if (response.status === 500) {
              //alert('Erreur pendant la procédure')
              //throw new Error(response.status);
              alert("Erreur interne, modification du mot de passe annulée.");
            }
            throw new Error(response.status);
          } else return response.json();
        })
        .then((data) => {
          if (data.info == "INCORRECTPASS") {
            alert(
              "Le mot de passe actuel est incorrect, veuillez l'encoder correctement !"
            );
            textInput.current.focus();
          } else {
            alert("Nouveau mot de passe enregistré !");
            formValidation.resetValues();
          }

          setLoading(false);
        })
        .catch((error) => {
          console.log("error: " + error);
          setLoading(false);
        });

      return;
    }
  };

  const formValidation = useForm(InitialValues, saveData, ValidationSchema);

  useEffect(() => {
    var bearerToken = AuthState.Token;
    if (bearerToken == null) {
      //Pas de token, on redirige vers le login
      //alert("Vous n'êtes pas connecté")
      AuthDispatch({ type: "LOGOUT" });
      history("/login/");
    }
  }, []); //S'il n'y avais pas le second parametre [sper], la fonction s'executerais à chaque rafraissiement d'écran
  //Avec le param [sper], elle est executée seulement au chargement: Elle remplace donc componentWillMount
  //En fait [sper] permet de définir une liste de variable qui declenche la fonction quand elles sont modifiées.
  //Sans cela, c'est warning car la moindre action déclencherais le useEffect

  return (
    <div className="card mb-2">
      <div className="card-header ">
        <strong>Changer mon mot de passe</strong>
      </div>
      <div className="card-body mb-2">
        <Row className="justify-content-center">
          <form className="col-sm-12" className="needs-validation" noValidate>
            <div className="row p-2  g-3 align-items-center">
              <div className="col-lg-4">
                <label htmlFor="pass">Mot de passe :</label>
              </div>
              <div className="col-lg-8">
                <InputValidationPassword
                  nom="pass"
                  validation={formValidation}
                  tabIndex="0"
                  reference={textInput}
                />
              </div>
            </div>
            <div className="row p-2  g-3 align-items-center">
              <div className="col-lg-4">
                <label htmlFor="newPass">Nouveau passe :</label>
              </div>
              <div className="col-lg-8">
                <InputValidationPassword
                  nom="newPass"
                  validation={formValidation}
                  tabIndex="0"
                />
              </div>
            </div>
            <div className="row p-2  g-3 align-items-center">
              <div className="col-lg-4">
                <label htmlFor="newPass2"> Répétez :</label>
              </div>
              <div className="col-lg-8">
                <InputValidationPassword
                  nom="newPass2"
                  validation={formValidation}
                  tabIndex="0"
                />
              </div>
            </div>
            <div className="row p-2  g-3 align-items-right">
              <div className="col-lg-12  d-flex justify-content-end">
                <Button
                  type="button"
                  onClick={formValidation.handleSubmit}
                  tabIndex="0"
                  disabled={loading ? `disabled` : ``}
                >
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm">
                        <span className="sr-only">Saving...</span>
                      </Spinner>
                      &nbsp; Sauvegarde...
                    </>
                  ) : (
                    <>Sauver</>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </Row>
      </div>
    </div>
  );
}

export default ChangePass;
