import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Spinner, Button, Card, Overlay, Popover } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';
import InputValidationPassword from '../FormValidation/InputValidationPassword.js';
import { PatientTemp } from './PatientTemp.js';
import { ReinitPassword } from './ReinitPassword.js';
import { CheckPatient } from './CheckPatient.js';
import { useAuthDispatch } from '../../Context/context.js';
import { BoutonItsme } from '../Login/BoutonItsme.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

export function LoginRDV() {
    const AuthDispatch = useAuthDispatch();
    const [loading, setLoading] = useState(false);
    const [panelLogin, setPanelLogin] = useState(false);
    const [panelNISS_SPER, setPanelNISS_SPER] = useState(false);
    const [panelNewPatient, setPanelNewPatient] = useState(false);
    const [panelInfosPatient, setPanelInfosPatient] = useState(false);
    const [patient, setPatient] = useState(null);
    const [niss, setNiss] = useState(null);
    const [sper, setSper] = useState(null);

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const InitialValues = { NISS: '', Password: '' };
    const ValidationSchema = yup.object().shape({
        NISS: yup.string()
            .required('Obligatoire'),
        Password: yup.string()
            .required('Obligatoire')
    });


    useEffect(() => {
        AuthDispatch({ type: 'LOGOUT' });
        affichePanelLogin();
    }, []);

    const Login = () => {
        const jssha = require('js-sha256')
        setLoading(true);
        function hash(input) {
            return jssha.sha256(input).toUpperCase();
        }

        const hashResult = hash(formValidation.values.Password)

        const valeurs = {
            NISS: formValidation.values.NISS,
            Password: hashResult
        }

        fetch("rdvo/login/", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(valeurs)
        })
            .then(response => {
                setLoading(false);
                if (!response.ok) {
                    if (response.status === 403) {
                        alert("Nom ou mot de passe incorrecte !")
                    }
                    else if (response.status === 401) {
                        alert('Trop de tentatives de connexion. Essayez dans 15 min.')
                    }
                    throw new Error(response.status);
                }
                else return response.json();
            })
            .then((data) => {
                setLoading(false);
                formValidation.values.NISS = '';
                formValidation.values.Password = '';
                window.open("/rendezvous/?token=" + data.token);
            })
            .catch(error => {
                setLoading(false);
                console.log('error: ' + error);
            });
    }

    const formValidation = useForm(InitialValues, Login, ValidationSchema);

    const affichePanelLogin = (event) => {
        if (event)
            event.preventDefault();
        setPanelLogin(true);
        setPanelNISS_SPER(false);
        setPanelNewPatient(false);
        setPanelInfosPatient(false);
    }

    const affichePanelNISS_SPER = (event) => {
        if (event)
            event.preventDefault();
        setPanelLogin(false);
        setPanelNISS_SPER(true);
        setPanelNewPatient(false);
        setPanelInfosPatient(false);
    }

    const affichePanelNewPatient = (event) => {
        if (event)
            event.preventDefault();
        setPanelLogin(false);
        setPanelNISS_SPER(false);
        setPanelNewPatient(true);
        setPanelInfosPatient(false);
    }

    const affichePanelInfosPatient = event => {
        if (event)
            event.preventDefault();
        setPanelLogin(false);
        setPanelNISS_SPER(false);
        setPanelNewPatient(false);
        setPanelInfosPatient(true);
    }

    const LoadPatient = (data, NISS, SPER) => {
        setPatient(data);
        setNiss(NISS);
        setSper(SPER);
    }

    function PopoverNISS() {

        return (
            <>
                <a href="#" className="input-group-text z-3" title="Comment trouver mon numéro de registre national belge" ref={ref} onClick={(event) => { event.preventDefault(); setShow(!show); setTarget(event.target) }}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </a>
                <Overlay target={target} show={show} placement="left" container={ref}>
                    <Popover id="popover-contained">
                        <Popover.Header>
                            Comment trouver mon numéro de registre national belge
                        </Popover.Header>
                        <Popover.Body>
                            <img className='img-responsive' src='dos_id.png.jpg' />
                            <p>Le numéro de registre national est un numéro d'identification unique qui est attribué aux personnes physiques inscrites en Belgique. Vous trouverez ce numéro au dos de votre carte d’identité. Ce code se compose de 11 chiffres et commence par votre date de naissance dans le format année-mois-jour.</p>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </>
        );
    }



    return (
        <>
            {panelLogin &&
                <Row>
                    <Col xs="12" sm="6" lg="6" xl="6" >
                        <Card className="mb-2" style={{ height: "100%" }}>
                            <Card.Header>
                                <h5 className="panel-title">Vous prenez rendez-vous pour la première fois</h5>
                            </Card.Header>
                            <Card.Body>
                                <p>Afin de protéger vos données personnelles et médicales, nous vous demandons d'établir <strong>votre identifiant et votre mot de passe</strong></p>
                                <p><em>Cette procédure est unique, vous ne devrez pas la réitérer lors de votre prochaine prise de rendez-vous.</em></p>
                                <div className="text-center">
                                    <Button className="btn btn-default" id="rdvCreateCptForm" onClick={affichePanelNISS_SPER} title="Créer votre compte">Créer votre compte</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="6" xl="6" >
                        <form id="rdvConnexion" style={{ height: "100%" }}>
                            <Card style={{ height: "100%", overflow: "visible" }}>
                                <Card.Header>
                                    <h5 className="card-title">Connectez-vous</h5>
                                </Card.Header>
                                <Card.Body>
                                    <div className="form-group">
                                        <div className="input-group" style={{ marginBottom: 15 }}>
                                            <InputValidation nom={'NISS'} validation={formValidation} placeholderInput="Votre numéro de registre national" popover={PopoverNISS} required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <InputValidationPassword nom={'Password'} validation={formValidation} placeholderInput="Votre mot de passe" required />
                                    </div>
                                    <p className="pull-right"><a id="rdvMdpOubliForm" href="#" title="Mot de passe oublié ?" onClick={affichePanelNISS_SPER}>Mot de passe oublié ?</a></p>
                                    <br className="clear" />
                                    <div className="text-center" style={{ marginBottom: 20 }}>
                                        {loading ? (<Button type="button" disabled >
                                            <Spinner animation="border" size="sm" >
                                                <span className="sr-only">Connexion...</span>
                                            </Spinner>
                                            &nbsp; Connexion...
                                        </Button>) : (<Button type="button" onClick={formValidation.handleSubmit} >Connexion</Button>)
                                        }
                                    </div>
                                    <div className="text-center" style={{ marginBottom: 20 }}>
                                        <BoutonItsme origine={"CHRH"} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </Col>
                </Row>
            }

            {panelNISS_SPER &&
                <CheckPatient LoadPatient={LoadPatient} affichePanelInfosPatient={affichePanelInfosPatient} affichePanelNewPatient={affichePanelNewPatient} />
            }
            {panelNewPatient &&
                <PatientTemp affichePanelLogin={affichePanelLogin} />
            }
            {panelInfosPatient &&
                <ReinitPassword donnees={patient} niss={niss} sper={sper} affichePanelLogin={affichePanelLogin} />
            }
        </>);
}