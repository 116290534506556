import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export function ConfirmMail() {
    const textInput = useRef(null);

    const history = useNavigate();
    let param_email = null;
    let param_code = null;

    const annuler = () => {
        history("/login");
    }

    const ConfirmMail = () => {
        debugger;
        const body = { Email: param_email,Code:param_code };
        fetch("donnees/ConfirmEmail/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then((data) => {
                alert('Si les informations encodées sont correctes, vous allez recevoir votre identifiant par mail...');
                history("/login/");
            }).catch(function (error) {
                alert('Une erreur interne est survenue, veuillez recommencer plus tard. Merci.');
                history("/login/");
            });
    }


    //Recuperer les params querystring
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    useEffect(() => {
        //Vérif si l'id est passé
        param_email = getParameterByName('Email'); //Appel depuis le site de l'hopital
        if (!param_email) {
            alert('Veuillez ouvrir cette page à partir du lien que vous avez recu dans le mail.');
            history("/login/");
        }
        param_code = getParameterByName('Code'); //Appel depuis le site de l'hopital
        if (!param_code) {
            alert('Veuillez ouvrir cette page à partir du lien que vous avez recu dans le mail.');
            history("/login/");
        }

        ConfirmMail();

    }, []);


    return (
        <div>
             <h1  >Confirmation d'adresse email</h1>
            <Container fluid style={{ paddingTop: 15 }} >
                <Row>

                    <Col xs="12" sm="11" lg="10" xl="9">
                        <div id="ListeFactures" className="card mb-2" >

                            <div className="card-header ">
                                <strong>Confirmation de votre mail</strong>

                            </div>
                            <div className="card-block mb-2">
                                <Row className="justify-content-center">
                                    Merci d'avoir confirmé votre mail

                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}



