import { useState, useEffect } from 'react'

function InputCheckboxValidation({ validation, nom, texte, displaySwitch = false, mb = '', trueValue = true, falseValue = false, desactive = false, reference, ...inputProps }) {
    let [valeur, setValeur] = useState(null);

    useEffect(
        () => {
            setValeur(validation.values ? (validation.values[nom] != null ? (validation.values[nom] == trueValue ? true : false) : (null)) : null)
        },
        [validation.values[nom]],
    )


    return (
        <div className="input-group">
            <div className={`form-check ${mb} ${displaySwitch && `form-switch`} ${validation.getError(nom) && `is-invalid`}`}>
                <input class="form-check-input"
                    type="checkbox"
                    checked={valeur}
                    onChange={(event) => { validation.handleChangeCheckbox(event, trueValue, falseValue) }}
                    name={nom}
                    id={nom}
                    disabled={desactive}
                    autoFocus={true}
                    ref={reference}
                    {...inputProps}
                />
                <label class="form-check-label" for={nom}>
                    {texte}
                </label>
            </div>

            {validation.getError(nom) && (
                <small className="invalid-feedback">
                    {validation.getError(nom)}
                </small>
            )}
        </div>
    );
}

export default InputCheckboxValidation;