import React, { useState, useEffect, useRef } from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

export function CheckPatient({ LoadPatient, affichePanelInfosPatient, affichePanelNewPatient }) {
    const textInput = useRef(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const [showSPER, setShowSPER] = useState(false);
    const [targetSPER, setTargetSPER] = useState(null);
    const refSPER = useRef(null);

    useEffect(() => {
        textInput.current.focus();
    }, [])

    const InitialValues = { NISS: '', Password: '' };
    const ValidationSchema = yup.object().shape({
        NISS: yup.string()
            .required('Obligatoire')
            .test("IsNissValid", "Votre numéro de registre national n'est pas valide", (niss) => {
                if (niss.length == 11) {
                    var resModulo = niss.substring(9);
                    var valueModulo = niss.substring(0, 9);

                    if (97 - (valueModulo % 97) == resModulo) return true;
                    else {
                        valueModulo = '2' + valueModulo;
                        if (97 - (valueModulo % 97) == resModulo) return true;
                    }
                }
                return false;
            }),
        SPER: yup.string()
            .required('Obligatoire')
    });



    const GetPatient = () => {

        const valeurs = {
            NISS: formValidation.values.NISS,
            SPER: formValidation.values.SPER
        }

        fetch("rdvo/getpatient/", {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(valeurs)
        })
            .then(response => response.json())
            .then((data) => {
                if (data != "null") {
                    LoadPatient(data, valeurs.NISS, valeurs.SPER);
                    affichePanelInfosPatient();
                }
            });
    }

    const formValidation = useForm(InitialValues, GetPatient, ValidationSchema)

    function PopoverNISS() {

        return (
            <>
                <a href="#" className="input-group-text z-3" title="Comment trouver mon numéro de registre national belge" ref={ref} onClick={(event) => { event.preventDefault(); setShow(!show); setTarget(event.target) }}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </a>
                <Overlay target={target} show={show} placement="left" container={ref}>
                    <Popover id="popover-contained">
                        <Popover.Header>
                            Comment trouver mon numéro de registre national belge
                        </Popover.Header>
                        <Popover.Body>
                            <img className='img-responsive' src='dos_id.png.jpg' />
                            <p>Le numéro de registre national est un numéro d'identification unique qui est attribué aux personnes physiques inscrites en Belgique. Vous trouverez ce numéro au dos de votre carte d’identité. Ce code se compose de 11 chiffres et commence par votre date de naissance dans le format année-mois-jour.</p>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </>
        );
    }


    function PopoverSPER() {

        return (
            <>
                <a href="#" className="input-group-text z-3" title="Comment trouver mon numéro de patient" ref={refSPER} onClick={(eventSPER) => { eventSPER.preventDefault(); setShowSPER(!showSPER); setTargetSPER(eventSPER.target) }}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </a>
                <Overlay target={targetSPER} show={showSPER} placement="left" container={refSPER}>
                    <Popover id="popover-containedSPER">
                        <Popover.Header>
                            Comment trouver mon numéro de registre national belge
                        </Popover.Header>
                        <Popover.Body>
                            <img className="img-responsive" src="patno.png" />
                            <p>Votre numéro de patient est indiqué sur vos factures de l'hôpital de Huy.</p>
                            <p>Vous le trouverez dans le coin supérieur droit dans une case grise.</p>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </>
        );
    }



    return (
        <div id="rdv_panel_2" class="row">
            <div class="col-md-12">
                <form className="needs-validation">
                    <div class="card" style={{ overflow: "visible" }}>
                        <div class="card-header">
                            <h3 class="card-title">Veuillez introduire vos données personnelles</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group" style={{ paddingBottom: 10 }}>
                                <InputValidation title="Votre numéro de registre national" nom={'NISS'} validation={formValidation} placeholderInput="Votre numéro de registre national" reference={textInput} popover={PopoverNISS} required />
                            </div>
                            <div class="form-group" style={{ paddingBottom: 10 }}>
                                <InputValidation title="Votre numéro de patient" nom={'SPER'} validation={formValidation} placeholderInput="Votre numéro de patient" popover={PopoverSPER} required />
                            </div>
                        </div>
                        <blockquote>
                            Vous ne possédez pas une de ces deux données ou souhaitez prendre rendez-vous pour un proche, prenez contact avec le service des rendez-vous au 085 27 27 27 ou utilisez le formulaire en ligne disponible <a href="#" onClick={affichePanelNewPatient} id="085 27 27 27" class="rdv_register">ici</a>
                        </blockquote>
                        <div class="card-footer text-center">
                            <Button type="button" title="Valider" onClick={formValidation.handleSubmit}>Valider</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
}