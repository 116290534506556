import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import LoadingSpinner from "../LoadingSpinner.js";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreferencesMail from './PreferencesMail.js';
import ChangePass from './ChangePass.js';


export function Monprofil() {
    const [loading, setLoading] = useState(true);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    const [donneesPerso, setDonneesPerso] = useState(null);
    const [medecin, setMedecin] = useState(null);

    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            setLoading(true);

            fetch('donnees/GetMesInfos/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    setDonneesPerso(data.donnees[0]);

                    setLoading(false);

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            fetch('donnees/GetMedecinTraitant/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    setMedecin(data.donnees[0]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []); //S'il n'y avais pas le second parametre [sper], la fonction s'executerais à chaque rafraissiement d'écran
    //Avec le param [sper], elle est executée seulement au chargement: Elle remplace donc componentWillMount
    //En fait [sper] permet de définir une liste de variable qui declenche la fonction quand elles sont modifiées. 
    //Sans cela, c'est warning car la moindre action déclencherais le useEffect

    return (
        <div>
            <h1  >Mon profil </h1>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&


                <div>
                    <Container fluid style={{ paddingTop: 15 }} className="mb-2">
                        <Row>
                            <Col xs="12" sm="12" lg="10" xl="6">
                                <div className="card mb-2" >

                                    <div className="card-header ">
                                        <strong>Mes informations</strong>
                                    </div>
                                    <div className="card-body mb-2">
                                        <Row className="justify-content-center">
                                            <form className="col-sm-12" >
                                                <div className="row p-2  g-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <label htmlFor="Nom" >Nom :</label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="Nom" className="form-control" tabIndex="0" readOnly value={donneesPerso.NOM} />
                                                    </div>
                                                </div>
                                                <div className="row p-2  g-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <label htmlFor="Adresse">Adresse :</label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <textarea rows="2" name="Adresse" className="form-control" tabIndex="0" readOnly value={donneesPerso.ADRESSE} />
                                                    </div>
                                                </div>
                                                <div className="row p-2  g-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <label htmlFor="Nationalite" >Nationalité :</label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="Nationalite" className="form-control" tabIndex="0" readOnly value={donneesPerso.NATIONALITE} />
                                                    </div>
                                                </div>
                                                <div className="row p-2  g-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <label htmlFor="Niss" >Numero national :</label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                    <input type="text" name="Niss" tabIndex="0" className="form-control" readOnly value={donneesPerso.NISS} pattern="^[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$"/>
                                                    </div>
                                                </div>
                                                <div className="row p-2  g-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <label htmlFor="Datenais" >Date de naissance :</label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <DatePicker name="Datenais" className="form-control" tabIndex="0" selected={moment(donneesPerso.DTE_NAI).toDate()} dateFormat="dd/MM/yyyy" tabIndex="5" readOnly />

                                                    </div>
                                                </div>
                                            </form>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            {medecin &&
                                <Col xs="12" sm="12" lg="10" xl="6">
                                    <div className="card mb-2" >

                                        <div className="card-header ">
                                            <strong>Mon médecin traitant</strong>
                                        </div>
                                        <div className="card-body mb-2">
                                            <Row className="justify-content-center">
                                                <form className="col-sm-12" >
                                                    <div className="row p-2  g-3 align-items-center">
                                                        <div className="col-lg-4">
                                                            <label htmlFor="NomMed" >Nom :</label>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <input type="text" name="NomMed" className="form-control" tabIndex="0" readOnly value={medecin.NOM} />
                                                        </div>
                                                    </div>
                                                    <div className="row p-2  g-3 align-items-center">
                                                        <div className="col-lg-4">
                                                            <label htmlFor="AdresseMed">Adresse :</label>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <textarea rows="2" name="AdresseMed" className="form-control" tabIndex="0" readOnly value={medecin.ADRESSE} />
                                                        </div>
                                                    </div>
                                                </form>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" lg="10" xl="6">
                                <PreferencesMail ></PreferencesMail>
                            </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12" lg="10" xl="6">
                            <ChangePass ></ChangePass>
                        </Col>
                    </Row>
                    </Container>
                </div>
            }
        </div>
    );
}



