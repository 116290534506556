import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';
import SelectValidation from '../FormValidation/SelectValidation.js';
import {  useAuthDispatch } from '../../Context/context.js';

export function ProfilTmpForm() {
    const textInput = useRef(null);
    const [loading, setLoading] = useState(false);

    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    function EmptyNumber(typeErrorMessage = "Entrez un nombre valide") {
        return yup.number()
            .transform(function (value, originalValue) {
                if (this.isType(value)) return value
                if (!originalValue || !originalValue.trim()) {
                    return null
                }
                // we return the invalid original value
                return originalValue
            })
            .nullable(true)
            .typeError(typeErrorMessage)
    }
    const InitialValues = { rdv_nom: '', rdv_prenom: '', rdv_sexe: '', rdv_ddn_dd: '', rdv_ddn_mm: '', rdv_ddn_yyyy: '', rdv_gsm: null, rdv_tel: null };
    const ValidationSchema = yup.object().shape({
        rdv_nom: yup.string()
            .trim()
            .required('Obligatoire')
            .min(2, 'Obligatoire'),
        rdv_prenom: yup.string()
            .trim()
            .required('Obligatoire')
            .min(2, 'Obligatoire'),
        rdv_sexe: yup
            .string()
            .required('Obligatoire')
            .matches(/^$|^(M|F)$/, "Obligatoire"),
        rdv_ddn_dd: EmptyNumber('1-31')
            .integer('1-31')
            .min(1, '1-31')
            .max(31, '1-31')
            .required('1-31'),
        rdv_ddn_mm: EmptyNumber('1-12')
            .integer('1-12')
            .min(1, '1-12')
            .max(12, '1-12')
            .required('1-12'),
        rdv_ddn_yyyy: EmptyNumber('>1900')
            .integer('>1900')
            .min(1900, '>1900')
            .max(new Date().getFullYear(), '<' + new Date().getFullYear().toString())
            .required('>1900'),
        rdv_tel: EmptyNumber('Entrez uniquement des chiffres')
            .integer('Entrez uniquement des chiffres')
            .test("", "Téléphone ou GSM obligatoire", function (value) {
                const { rdv_gsm } = this.parent;
                if (!rdv_gsm) return value != null
                return true
            }),
        rdv_gsm: EmptyNumber('Entrez uniquement des chiffres')
            .integer('Entrez uniquement des chiffres')
            .test("", "Téléphone ou GSM obligatoire", function (value) {
                const { rdv_tel } = this.parent;
                if (!rdv_tel) return value != null
                return true
            })
    })

    const CreerCompte = () => {
        setLoading(true);

        const body = {
            Nom: formValidation.values["rdv_nom"],
            Prenom: formValidation.values["rdv_prenom"],
            Sexe: formValidation.values["rdv_sexe"],
            DteNais_dd: parseInt(formValidation.values["rdv_ddn_dd"]),
            DteNais_mm: parseInt(formValidation.values["rdv_ddn_mm"]),
            DteNais_yyyy: parseInt(formValidation.values["rdv_ddn_yyyy"]),
            Tel: formValidation.values["rdv_tel"] != "" ? parseFloat(formValidation.values["rdv_tel"]) : null,
            Gsm: formValidation.values["rdv_gsm"] != "" ? parseFloat(formValidation.values["rdv_gsm"]) : null,
        };
        fetch("donnees/PatientTemp/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                setLoading(false);
                if (!response.ok) {
                    //dispatch({ type: 'LOGIN_ERROR' });
                    alert('Erreur de création du compte temporaire')
                    throw new Error(response.status);
                }
                else return response.json();
            })
            .then((data) => {
                AuthDispatch({ type: 'LOGIN_FROM_MYCHRH', payload: data.token });
                //S'il y a un param de redirection, l'utiliser sinon, on redirige vers RDV
                history("/rendezvous/");
            })
            .catch(error => {
                setLoading(false);
                console.log('error: ' + error);
            });
    }

    const submit = () => {
        CreerCompte();
    }
    const formValidation = useForm(
        InitialValues,
        submit,
        ValidationSchema,
    )

    useEffect(() => {
        textInput.current.focus();
    }, []);


    return (
        <Row className="justify-content-center">
            <Col xs="12" sm="11" lg="10" xl="9">
                <div id="ListeFactures" className="card mb-2" >

                    <div className="card-header ">
                        <strong>Création d'une fiche signalétique temporaire</strong>

                    </div>
                    <div className="card-block mb-2">
                        <Row className="justify-content-center">
                            <form className="needs-validation" novalidate className="col-sm-10">
                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">
                                        <label htmlFor="rdv_nom" >Nom *</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <InputValidation nom="rdv_nom" placeholder="Nom" validation={formValidation} reference={textInput} />
                                    </div>
                                </div>
                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4 ">
                                        <label htmlFor="rdv_prenom" >Prénom *</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <InputValidation nom="rdv_prenom" placeholder="Prénom" validation={formValidation} />
                                    </div>
                                </div>
                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4 ">
                                        <label htmlFor="rdv_sexe" >Sexe *</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <SelectValidation nom="rdv_sexe" validation={formValidation} placeholder='Sexe'>
                                            <option value="M">Masculin</option>
                                            <option value="F">Féminin</option>

                                        </SelectValidation>
                                    </div>
                                </div>
                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">
                                        <label htmlFor="rdv_ddn" >Date de naissance *</label>
                                    </div>
                                    <div className="col-lg-2 col-sm-4">
                                        <InputValidation type="number" nom="rdv_ddn_dd" min="1" max="31" maxlength="2" placeholder="jj" validation={formValidation} />
                                    </div>
                                    <div className="col-lg-2 col-sm-4">
                                        <InputValidation type="number" nom="rdv_ddn_mm" min="1" max="12" maxlength="2" placeholder="mm" validation={formValidation} />
                                    </div>
                                    <div className="col-lg-3 col-sm-4">
                                        <InputValidation type="number" nom="rdv_ddn_yyyy" min="1900" max={new Date().getFullYear()} maxlength="4" placeholder="aaaa" validation={formValidation} />
                                    </div>
                                </div>

                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">
                                        <label htmlFor="rdv_tel" >Téléphone</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <InputValidation type="tel" nom="rdv_tel" maxlength="9" placeholder="Télephone" validation={formValidation} />


                                    </div>
                                </div>

                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">
                                        <label htmlFor="rdv_gsm" >GSM</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <InputValidation type="tel" nom="rdv_gsm" maxlength="10" placeholder="GSM" validation={formValidation} />

                                    </div>
                                </div>
                                <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">&nbsp;</div>
                                    <div className="col-lg-8">
                                        <div>
                                            {loading ? (<Button type="button" disabled >
                                                <Spinner animation="border" size="sm" >
                                                    <span className="sr-only">Création...</span>
                                                </Spinner>
                                                &nbsp; Création...
                                            </Button>) : (<Button type="button" onClick={formValidation.handleSubmit} tabIndex="6">Créer mon compte</Button>)
                                            }

                                        </div>

                                    </div>
                                </div>
                            </form>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
}



