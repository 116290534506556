import React from 'react'
function InputValidationPassword({ nom, validation, desactive = false, reference = null, placeholder = null, ...inputProps }) {
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            validation.handleSubmit();
        }
    }

    return (
        <>
            <div className="input-group">
                <input name={nom}
                    type="password"
                    className={`form-control shadow-none ${validation.getError(nom) && `is-invalid`}`}
                    disabled={(desactive) ? "disabled" : ""}
                    onChange={validation.handleChange}
                    value={validation.values[nom]}
                    ref={reference}
                    onKeyDown={keyPress}
                    {...inputProps}
                />
                {placeholder &&
                    <label>{placeholder}</label>
                }
                {validation.getError(nom) && (
                    <small className="invalid-feedback">
                        {validation.getError(nom)}
                    </small>
                )}
            </div>
        </>
    );
}

export default InputValidationPassword;