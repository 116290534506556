import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { RendezVous } from './components/MesRendezvous/RendezVous';
import { NouveauRDV } from './components/NouveauRendezvous/NouveauRDV';
import { PreAdmissions } from './components/PreAdmissions/PreAdmissions';
import { RDVProvider } from './Context/context.js';
import { RegisterOrLogin } from './components/Login/RegisterOrLogin';
import { Factures } from './components/Factures/Factures.js';
import { Documents } from './components/MesDocuments/Documents.js';
import { Fas_auth } from './components/Login/fas_auth.js';
import { AliasPerdu } from './components/Profil/AliasPerdu.js';
import { PassOublie } from './components/Profil/PassOublie.js';
import { NouveauPass } from './components/Profil/NouveauPass.js';
import { Monprofil } from './components/Profil/Monprofil.js'
import { Enregistrement } from './components/Profil/Enregistrement.js'
import { ProfilTmp } from './components/Profil/ProfilTmp.js'
import { ConfirmMail } from './components/Profil/ConfirmMail.js'
import './custom.scss'
import { LoginRDV } from './components/LoginRDV/LoginRDV';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>

                < RDVProvider>
                    <Routes>
                        <Route path='/' element={<RendezVous />} />
                        <Route path='index.html' element={<RendezVous />} />
                        <Route path='/factures' element={<Factures />} />
                        <Route path='/rendezvous' element={<RendezVous />} />
                        <Route path='/documents' element={<Documents />} />
                        <Route path='/preadmissions' element={<PreAdmissions />} />
                        <Route path='/login' element={<RegisterOrLogin />} />
                        <Route path='/nouveau-rendezvous' element={<NouveauRDV />} />
                        <Route path='/fas_auth.aspx' element={<Fas_auth />} />
                        <Route path='/identifiant-oublie' element={<AliasPerdu />} />
                        <Route path='/pass-oublie' element={<PassOublie />} />
                        <Route path='/Account/NouveauPass.aspx' element={<NouveauPass />} />
                        <Route path='/Account/login.aspx' element={<RegisterOrLogin />} />
                        <Route path='/Account/MonProfil' element={<Monprofil />} />
                        <Route path='/Account/register.aspx' element={<Enregistrement />} />
                        <Route path='/Account/ProfilTmp' element={<ProfilTmp DisplayMessage={false}/>} />
                        <Route path='/Account/ConfirmProfilTmp' element={<ProfilTmp DisplayMessage={true} />} />
                        <Route path='/Account/ConfirmMail' element={<ConfirmMail />} />
                        <Route path='/loginRDV' element={<LoginRDV />} />

                    </Routes>
                </ RDVProvider>

            </Layout>
        );
    }
}
