import React, { useState, useEffect } from 'react';
import { Col, Button, Card, Table, Spinner } from 'react-bootstrap';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";
import "../font-awesome.css";

export function MonRendezvous({ item, isFutur }) {
    const [cancelButton, setCancelButton] = useState(true);
    const [deleted, setDeleted] = useState(false);
    const [rdvAuj, setRdvAuj] = useState(false);

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);

    const annulerRdv = () => {
        var bearerToken = AuthState.Token;//token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }

        if (rdvAuj) {
            alert("Il est impossible d'annuler un rdv le jour même. Veuillez téléphoner au 085/27.27.27. Merci");
            return;
        }
        if (window.confirm('Etes vous sûr de vouloir supprimer le rendez-vous du '+  item.DATETEXTE + ' ?')) {

            setLoading(true);

            fetch("donnees/rendezvous/" + item.IDRDV,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`
                    }
                })
                .then(response => {
                    setLoading(false);
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        else if (response.status === 405) {
                            alert("Impossible de supprimer ce rendez-vous. Veuillez sonner à l'accueil de l'hôpital. Merci");
                            return;
                        }
                        throw new Error(response.status);
                    }
                    else {
                        setDeleted(true);
                        alert('Rendez vous supprimé avec succès !');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    useEffect(() => {
        //Est-ce possible d'annuler le rdv
        let cancelable = (isFutur && item.RLO_INTERNET === 'O' && (item.SSER == null || (item.SSER != null && !item.SSER.startsWith('7000'))));
        setCancelButton(cancelable);

        //Est-ce un rendezvous ce jour ?
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        let dtRdv = new Date(item.DTRDV);
        dtRdv.setUTCHours(0, 0, 0, 0);

        setRdvAuj(cancelable && (today.getTime() === dtRdv.getTime()));

    }, [])

    return (
        <>
            {!deleted &&
                <Col xs="12" sm="6" lg="6" xl="6" >
                <Card className="mb-2">
                        <Card.Header>
                        <strong>{item.DATETEXTE} </strong>
                        </Card.Header>
                        <Card.Body>
                            <Table striped >
                                <tbody>
                                    <tr>
                                        <td>
                                            <i className="fa fa-fw fa-user-md"></i>
                                        </td>
                                        <td>
                                            {item.NOMMEDECIN}<br />
                                            {item.AGENDA} <br />
                                            {item.SERVICE}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <i className="fa fa-fw fa-map-marker"></i>
                                        </td>
                                        <td>
                                            {item.SALLE}<br />
                                            {item.BATIMENT}
                                        </td>
                                    </tr>

                                </tbody>
                            </Table>
                            {cancelButton &&

                                (loading ? (
                                    <Button variant="secondary" disabled >
                                        <Spinner animation="border" size="sm" >
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        &nbsp; Suppression...
                                    </Button>
                                )
                                    : (

                                <Button onClick={annulerRdv} variant="outline-secondary" disabled={rdvAuj}>Annuler ce rendez-vous</Button>
                                    ))
                            }

                        </Card.Body>
                    </Card >
                </Col>
            }
        </>);
}

export default MonRendezvous;