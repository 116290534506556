import React, { useReducer } from 'react';
import { RDVState, RDVReducer } from './RDVReducer';
import { AuthState, AuthReducer } from './AuthReducer';

const RDVStateContext = React.createContext();
const RDVDispatchContext = React.createContext();
const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();



export function useRDVState() {
	const context = React.useContext(RDVStateContext);
	if (context === undefined) {
		throw new Error('useRDVState must be used within a RDVProvider');
	}

	return context;
}

export function useRDVDispatch() {
	const context = React.useContext(RDVDispatchContext);
	if (context === undefined) {
		throw new Error('useRDVDispatch must be used within a RDVProvider');
	}

	return context;
}

export const RDVProvider = ({ children }) => {
	const [user, dispatch] = useReducer(RDVReducer, RDVState);

	return (
		<RDVStateContext.Provider value={user}>
			<RDVDispatchContext.Provider value={dispatch}>
				{children}
			</RDVDispatchContext.Provider>
		</RDVStateContext.Provider>
	);
}


//Auth Context
export function useAuthState() {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}

	return context;
}

export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a AuthProvider');
	}

	return context;
}

export const AuthProvider = ({ children }) => {
	const [user, dispatch] = useReducer(AuthReducer, AuthState);

	return (
		<AuthStateContext.Provider value={user}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
};