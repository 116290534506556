import React, { useState, useEffect } from 'react'
import { ToggleButton, ButtonGroup, ListGroup, Col, Row } from 'react-bootstrap';
import { useRDVState, useRDVDispatch, useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";

function ChoixMedecin() {
    const [medecins, setMedecins] = useState([]);

    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    const handleSingleCheck = e => {
        if (medecins.length == 1) return;
        RDVDispatch({ type: 'SET_MEDECINS', payload: { ...RDVState.Medecins, [e.target.value]: e.target.checked } });

    };


    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
            return;
        }
        
        fetch("/donnees/getagendas/" + RDVState.Service.IDSERVICE + "/" + RDVState.TypeRDV.IDTYPERDV, {
            method: 'get',
            headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
        })
            .then(response => response.json())
            .then((data) => {
                //Selectionner tous les medecins
                let MedSelected = [];
                for (var i = 0; i < data.length; i++) {
                    MedSelected = { ...MedSelected, [data[i].IDAGENDA]: true };
                }
if (RDVState.TypeRDV.IDTYPERDV != RDVState.MedecinsForTypeRDV)
{
                RDVDispatch({ type: 'SET_MEDECINS', payload: MedSelected })
}
                setMedecins(data);
                
            })
    }, [RDVState.Service.IDSERVICE, RDVState.TypeRDV.IDTYPERDV]); 

    return (
        <div>
            {medecins && medecins.map((item) => {
                return (
                    <div className="form-check" key={item.IDAGENDA}>
                        <input className="form-check-input" type="checkbox"
                            value={item.IDAGENDA} checked={RDVState.Medecins[item.IDAGENDA]}
                            onChange={handleSingleCheck} id={`med_${item.IDAGENDA}`}                      />
                        <label className="form-check-label" htmlFor={`med_${item.IDAGENDA}`} >
                            {item.NOMAGENDA}
                        </label>
                    </div>
                )
            })}

        </div>

    )

}

export default ChoixMedecin