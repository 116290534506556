import React, { useState, useEffect, useRef } from 'react'
import { Col, Button, Row, Spinner } from 'react-bootstrap';
import {  useRDVDispatch } from '../../Context/context.js';
import './ChoisirRDV.css';
import "../font-awesome.css";
import * as bootstrap from 'bootstrap';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";

function ChoisirRDV({ resultats, annuler }) {
    const RDVDispatch = useRDVDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const SubmitButtonRef = React.createRef()
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    const [modal, setModal] = useState(null);
    const [confirmation, setConfirmation] = useState(false); //Définir si la confirmation a deja ete lancée pour griser le bouton et empecher le double rdv

    const SetBtnActif = (btn) => {
        let version = bootstrap.Tooltip.VERSION
        if (activeItem != null && activeItem == btn) {
            setActiveItem(null);
        }
        else {
            setActiveItem(btn);
            // scrollToBottom()
            // handleShow();
            
            let  modalObj = new bootstrap.Modal(document.getElementById('ConfirmationModal'), {
                keyboard: false
            })
            modalObj.show()
            setModal(modalObj);

            //Quand on fermera la modal, on déselectionne l'item
            document.getElementById('ConfirmationModal').addEventListener('hidden.bs.modal', function (event) {
                setActiveItem(null);
            })
        }
    }

    const Reservation = () => {
        if (activeItem != null && confirmation == false) {
            setConfirmation(true);
            var bearerToken = AuthState.Token;//token;
            if (bearerToken == null) {
                //Pas de token, on redirige vers le login
                //alert("Vous n'êtes pas connecté")
                AuthDispatch({ type: 'LOGOUT' });
                history("/login/");
                setConfirmation(false);
                return;
            }

            fetch("donnees/rendezvous/",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearerToken}`
                    },
                    body: JSON.stringify(activeItem)
                })
                .then(response => {
                    setConfirmation(false);
                    if (!response.ok) {
                        if (response.status === 502) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert('Erreur interne. Prise de rendez-vous en ligne impossible pour le moment !')
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
            .then((data) => {
                    if (data.ok) {
                        if (data.info == "-1") {
                            //Plage non libre
                            alert("La plage n'est plus disponible. Veuillez sélectionner une autre plage horaire.");
                            modal.hide();
                        }
                        else {
                            alert('Votre rendez-vous est enregistré.');
                            modal.hide();
                            //Reset recherche
                            RDVDispatch({ type: 'RESET' });
                            history("/rendezvous/");
                        }
                    }
                    else {
                        alert("La plage n'est plus disponible. Veuillez sélectionner une autre plage horaire.");
                        modal.hide();
                    }
                }).catch(function (error) {
                    console.log(error);
                    
                });
        }
    }



    const scrollToBottom = () => {
        SubmitButtonRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <div className="mb-12">

            < Col xs="12" >

                {(!resultats || resultats.Dates.length == 0) &&

                    <p>Aucun résultat trouvé pour les critères rentrés</p>

                }
                {resultats && resultats.Dates.length > 0 && resultats.Dates.map((DatesRDV) => {
                    return (
                        <div className="card mb-2" >

                            <div className="card-header ">
                                <i className="fa fa-fw fa-calendar-o"></i> {DatesRDV.Date}
                            </div>
                        
                            {DatesRDV.AgendasSites && DatesRDV.AgendasSites.map((AgendaSite) => {
                                return (
                                    <>
                                        <div className="titleAgendaSite">
                                            <div className="SousTitleAgendaSite">
                                                <div className="SousSousTitleAgendaSite">
                                                    <i className="fa fa-fw fa-map-marker"></i> {AgendaSite.Site}
                                                </div>
                                                <i className="fa fa-fw fa-user-md"></i>{AgendaSite.Agenda} {AgendaSite.IsNotConventionne && <span> (non-conventionné) </span>}<br />
                                            </div>
                                        </div>

                                        <div className="card-block plageLibre">
                                            <div className="plageLibreDeck">
                                                {AgendaSite.Plages && AgendaSite.Plages.map((Plage) => {
                                                    return (
                                                        <button type="button" key={Plage.UniqueID}
                                                            onClick={() => { SetBtnActif(Plage); }}
                                                            ref={SubmitButtonRef}
                                                            className={`col-xl-4 col-lg-4 col-sm-4 col-xs-4 col-4 hour ${activeItem != null && activeItem == Plage ? "hour_active" : ""}`}>
                                                            {Plage.LibHeure}
                                                        </button>

                                                    );
                                                })}

                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    )})
                }
            </Col>
            < Col xs="12" >
                <div style={{ float: 'right' }} >
                    <Button variant="secondary" onClick={annuler}>Précédent</Button> 
                </div>

            </ Col>

            <br />
            <br />
            {/* La boite de dialogue modale */}
            <div className="modal fade" id="ConfirmationModal" tabindex="-1" aria-labelledby="ConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-lg-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Confirmez le rendez-vous</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ></button>
                        </div>
                        <div className="modal-body">
                            {activeItem &&
                                <>
                                    <Row>
                                        <div className="col-sm-1 col-1"><i className="fa fa-user-md fa-2x"></i></div>
                                        <div className="col-sm-11 col-11">
                                            <b>{activeItem.LibTypeRDV}</b><br />
                                            {activeItem.NomAgenda}<br />
                                            {activeItem.LibService}<br />
                                        </div>
                                    </Row>
                                    <div className="row">
                                        <div className="col-sm-1 col-1"><i className="fa fa-clock-o fa-2x"></i></div>
                                        <div className="col-sm-11 col-11">
                                            <b>{activeItem.LibDate}</b><br />
                                            <b>{activeItem.LibHeure}</b>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1 col-1"><i className="fa fa-map-marker fa-2x"></i></div>
                                        <div className="col-sm-11 col-11">
                                            {activeItem.LibSBAT}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            {confirmation ? (
                                <button type="button" className="btn btn-primary" disabled >
                                    <Spinner animation="border" size="sm" >
                                        <span className="sr-only">Confirmation...</span>
                                    </Spinner>
                                    &nbsp; Confirmation...
                                </button>
                            )
                                : (

                                    <button type="button" className="btn btn-primary" onClick={Reservation} disabled={confirmation} >Confirmer</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default ChoisirRDV