import React from 'react'
import './SelectValidation.css'

function SelectValidation({ validation, nom, desactive = false, reference, placeholder,children, ...inputProps }) {
    return (
        <div className="input-group">
            <select
                name={nom}
                className={`form-select SelectValidation ${validation.getError(nom) && `is-invalid`}`}
                onChange={validation.handleChange}
                value={validation.values[nom]}
                autoFocus={true}
                ref={reference}
                {...inputProps}
            >
                {placeholder &&
                    <option value="" disabled selected hidden>{placeholder}</option>
                }
                {children}
            </select>

            {validation.getError(nom) && (
                <small className="invalid-feedback">
                    {validation.getError(nom)}
                </small>
            )}
        </div>
    );
}

export default SelectValidation;