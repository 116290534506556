import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import LoadingSpinner from "../LoadingSpinner.js";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation.js';
import InputValidationPassword from '../FormValidation/InputValidationPassword.js';
import InputCheckboxValidation from '../FormValidation/InputCheckboxValidation.js';

function PreferencesMail() {
    const [loading, setLoading] = useState(true);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    const [data, setData] = useState(true);

    const InitialValues = { facturesParMail: false, email:'' };
    const ValidationSchema = yup.object().shape({
        email: yup.string()
            .email("Mauvais format de l''email")
            .required("Obligatoire")
    });

    const saveData = () => {
        var bearerToken = AuthState.Token;
        if (bearerToken != null) {
            const valeurs = {
                Email: formValidation.values.email,
                FacturesParMail: formValidation.values.facturesParMail
            }
            debugger;
            //Sauvegarde Itsme
            fetch("donnees/SetPreferencesMail/",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearerToken}`
                    },
                    body: JSON.stringify(valeurs)
                })
                .then(response => {
                        if (!response.ok) {
                            if (response.status === 403) {

                                //dispatch({ type: 'LOGIN_ERROR' });
                                alert("La session a expiré.")
                                AuthDispatch({ type: 'LOGOUT' });
                                history("/login/");
                            }
                            if (response.status === 400) {
                                //alert('Erreur pendant la procédure')
                                //throw new Error(response.status);
                            }
                            throw new Error(response.status);
                        }
                        else return response.json();
                    })

                .then((data) => {
                    if (data.status == 400) {
                            alert('Erreur pendant la procédure !')
                    }
                    else {
                        alert('Données sauvegardées');
                    }

                })
                .catch(error => {
                    console.log('error: ' + error);
                });


            return;
        }
    }

    const formValidation = useForm(
        InitialValues,
        saveData,
        ValidationSchema,
    )

    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            setLoading(true);

            fetch('donnees/GetPreferencesMail/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    setData(data.donnees[0]);
                    
                    formValidation.setCtrlValue('email', data.donnees[0].CT_VAL); 
                    formValidation.setCtrlValue('facturesParMail', (data.donnees[0].RLO_ACCORD == 'O' ? true : false));
                    setLoading(false);

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            setLoading(false);
        }
    }, []); //S'il n'y avais pas le second parametre [sper], la fonction s'executerais à chaque rafraissiement d'écran
    //Avec le param [sper], elle est executée seulement au chargement: Elle remplace donc componentWillMount
    //En fait [sper] permet de définir une liste de variable qui declenche la fonction quand elles sont modifiées. 
    //Sans cela, c'est warning car la moindre action déclencherais le useEffect

    return (
        <div className="card mb-2" >

            <div className="card-header ">
                <strong>Préférences mail</strong>
            </div>
            <div className="card-body mb-2">
                <Row className="justify-content-center">
                    <form className="col-sm-12" className="needs-validation" noValidate>
                        <div className="row p-2  g-3 align-items-center">
                            <div className="col-lg-4">
                                <label htmlFor="email" >Email :</label>
                            </div>
                            <div className="col-lg-8">
                                <InputValidation nom="email" validation={formValidation} tabIndex="0"  />
                            </div>
                        </div>
                        <div className="row p-2  g-3 align-items-center">
                            <div className="col-lg-4">
                                &nbsp;
                            </div>
                            <div className="col-lg-8">
                                <InputCheckboxValidation nom={'facturesParMail'}
                                    validation={formValidation}
                                    mb="2"
                                    displaySwitch={true}
                                    texte="Recevoir mes factures par mail"
                                    tabIndex="0"
                                />
                            </div>
                        </div>
                        <div className="row p-2  g-3 align-items-right">
                            <div className="col-lg-12  d-flex justify-content-end">
                                <Button type="button" onClick={formValidation.handleSubmit} tabIndex="0">Sauver</Button>
                            </div>
                        </div>
                    </form>
                </Row>
            </div>
        </div>
    );
}

export default PreferencesMail



