import React, { useState, useEffect } from 'react';
import ChoixConsultation from './ChoixConsultation.js';
import ChoixSite from './ChoixSite.js';
import ChoixMedecin from './ChoixMedecin.js';
import ChoixJours from './ChoixJours.js';
import ChoisirRDV from './ChoisirRDV.js';
import {  useRDVState, useRDVDispatch } from '../../Context/context.js';
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap';
import NavigationNouveauRDV from "./NavigationNouveauRDV.js";
import LoadingSpinner from "../LoadingSpinner.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";
import { faItalic } from '@fortawesome/free-solid-svg-icons';



export function NouveauRDV() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();
    const [resultats, setResultats] = useState(null);
    const [prevDate, setPrevDate] = useState(null);
    const [nextDate, setNextDate] = useState(null);

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    useEffect(() => {
        // Met à jour le titre du document via l’API du navigateur
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
            return;
        }

        RDVDispatch({ type: 'RESET' });

        fetch("donnees/getservice", {
            method: 'get',
            headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
        })
            .then(response => response.json())
            .then((data) => {
                setServices(data);
                setLoading(false);
            })
    }, []);  //[] = liste de variable qui declenche une execution de la fonction lorsque que leur valeur change
    //avec une liste vide [], use effect ne s'execute qu'une fois au chargement de la page

    useEffect(() => {  //lorsque la date est changée, on réactive la recherche
        if (resultats != null && RDVState.Service != null) { //Si on est dans la recherche de rdv et qu'on navige dans les période avant et apres
            recherche();
        }
    },[RDVState.Date,RDVState.RechercheEnAvant])

    const RetourTypeRdv = () => {
        RDVDispatch({ type: 'SET_TYPERDV', payload: null });
    }

    const RetourTypeConsultation = () => {
        RDVDispatch({ type: 'SET_SERVICE', payload: null });
    }

    const selectService = (item) => {
        RDVDispatch({ type: 'SET_SERVICE', payload: item });
    }

    const annulerRecherchePlageLibre = () => {
        setResultats(null);
    }

    const recherchePrecedents = () => {
        RDVDispatch({ type: 'SET_DATE_RECH_ARRIERE', payload: prevDate });
        
    }

    const rechercheSuivants = () => {
        RDVDispatch({ type: 'SET_DATE', payload: nextDate });
    }

    const countCheckedItems = (JSonObject) => {
        let nb = 0;
        for (var key in JSonObject) {
            if (JSonObject[key]) { nb = nb + 1; }
        }
        return nb;
    }

    const recherche = () => {
        //Test si le formulaire est bien rempli
        setLoading(true);
        let nbMedecins = countCheckedItems(RDVState.Medecins);
        let nbSites = countCheckedItems(RDVState.Sites);
        let nbJours = countCheckedItems(RDVState.Jours);

        if (nbSites <= 0) {
            alert('Vous devez choisir au moins un site pour lancer une recherche');
            return;
        }
        if (nbMedecins <= 0) {
            alert('Vous devez choisir au moins un médecin pour lancer une recherche');
            return;
        }
        if (nbJours <= 0) {
            alert('Vous devez choisir au moins un jour pour lancer une recherche');
            return;
        }

        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
            return;
        }
        
        fetch("donnees/recherche/" + RDVState.Service.IDSERVICE + "/" + RDVState.TypeRDV.IDTYPERDV,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}` 
                },
                body: JSON.stringify(RDVState)
            })
            .then(response => response.json() )
            .then((data) => {
                //console.log(data.length);
                //console.log(data.Dates.length)
                var json = JSON.parse(data);
                console.log(json.Dates);
                console.log(json.Dates.length);
                console.log(json.RechercheEnAvant);
                console.log(json.DateRecherche);

                Date.prototype.addDays = function (days) {
                    var date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                }

                var pDate = null;
                var nDate = null;

                if (json.RechercheEnAvant) {
                    //Si on vient de faire une recherche en avant et qu'il n'y a pas de resultat, la ndate doit etre null;
                    nDate = null;
                    if (json.Dates.length > 0) {
                        nDate = new Date(Math.max(...json.Dates.map(e => new Date(e.DateObj)))).addDays(1);
                        nDate.setTime(nDate.getTime() - nDate.getTimezoneOffset() * 60 * 1000); //Tiens compte du GMT + 2
                    }
                    var pDate = json.DateRecherche;
                    
                }
                else {
                    pDate = null;
                    nDate = json.DateRecherche;

                    if (json.Dates.length > 0) {
                        pDate = new Date(Math.min(...json.Dates.map(e => new Date(e.DateObj)))).addDays(-30);
                        pDate.setTime(pDate.getTime() - pDate.getTimezoneOffset() * 60 * 1000);

                        nDate = new Date(Math.max(...json.Dates.map(e => new Date(e.DateObj)))).addDays(1);
                        nDate.setTime(nDate.getTime() - nDate.getTimezoneOffset() * 60 * 1000); //Tiens compte du GMT + 2
                    }

                    
                }
                
                setNextDate(nDate);
                setPrevDate(pDate);

                console.log(nDate);
                console.log(pDate);

                //Recherche de la date min et date max
                /*json.Dates.map((DatesRDV) => {

                });*/
                
                /*var nDate = new Date(Math.max(...json.Dates.map(e => new Date(e.DateObj )))).addDays(1);
                nDate.setTime(nDate.getTime() - nDate.getTimezoneOffset() * 60 * 1000); //Tiens compte du GMT + 2
                setNextDate(nDate);
                console.log(nDate);
                var pDate = new Date(Math.min(...json.Dates.map(e => new Date(e.DateObj)))).addDays(-1);
                pDate.setTime(pDate.getTime() - pDate.getTimezoneOffset() * 60 * 1000); //Tiens compte du GMT + 2
                setPrevDate(pDate);
                console.log(pDate);*/

                setLoading(false);
                setResultats(json);
                
            }).catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    return (
        < >
            {/*
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className={`breadcrumb-item ${RDVState.Service == null ? "active" : ""}`} >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                            </svg>
                           
                    </li>
                    {RDVState.Service != null && <li className={`breadcrumb-item ${RDVState.TypeRDV == null ?"active" : ""}`}>{RDVState.Service.NOMSERVICE}</li>}
                    {RDVState.TypeRDV != null && <li className={`breadcrumb-item ${resultats == null ? "active" : ""}`}>{RDVState.TypeRDV.NOMTYPERDV}</li>}
                    {resultats && <li className="breadcrumb-item active" >Recherche plage libre</li>}

                </ol>
            </nav>
            */}
            <h1 id="tabelLabel" >Nouveau Rendez-vous</h1>
            <NavigationNouveauRDV resultats={resultats} setResultats={setResultats}/>
            
            { !resultats  &&
                <>
                {RDVState.Service == null &&
                    <Row>
                        <Col xs="12" sm="10" lg="8" xl="6">

                            <div className="card mb-2" >

                                <div className="card-header ">
                                    <strong>Choisissez le service </strong>
                                </div>
                                <div className="card-body mb-2">
                                    {loading && <LoadingSpinner />}
                                    {!loading &&
                                        <>
                                            <ListGroup>
                                                {services != null && services.map((item) => {
                                                    return (
                                                        <ListGroup.Item key={item.IDSERVICE} action onClick={() => selectService(item)}>
                                                            {item.NOMSERVICE}
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                            </ListGroup>


                                        </>
                                    }
                                    {RDVState.Service &&
                                        <>
                                            <ChoixConsultation />
                                            <Button variant="secondary" >Précédent</Button>
                                        </>
                                    }
                                    {RDVState.TypeRDV &&
                                        <>
                                            <ChoixSite />
                                            <ChoixMedecin />
                                            <ChoixJours />
                                        </>
                                    }
                                </div>
                            </div>

                        </Col>
                        <Col xs="12" sm="10" lg="8" xl="6">
                            <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">Votre service ne figure pas dans la liste ?</h4>
                                <hr />
                                <p> Les rendez-vous pour les services suivants ne peuvent se prendre que par téléphone :<br /><br />
                                    <ul>
                                        <li>Service de Dentisterie :  <a href="tel:+3285272733">085/27.27.33</a></li>
                                        <li>Service d’Imagerie Médical (radiologie, scanner et IRM) : <a href="tel:+3285272731">085/27.27.31</a></li>
                                        <li>Service de Médecine Nucléaire (scintigraphie, un SPECT-CT ou un breath test) : <a href="tel:+3285272732">085/27.27.32</a></li>
                                        <li>Service de Gastro-Entérologie et Endoscopie : <a href="tel:+3285277460">085/27.74.60</a></li>
                                        <li>Service de Psychiatrie : <a href="tel:+3285277161">085/27.71.61</a></li>
                                        <li>Service Médecine physique / Clinique du sport : <a href="tel:+3285277163">085/27.71.63</a></li>
                                    </ul>
                                </p>
                            </div>
                        </Col>


                    </Row>
                }
                
                    {RDVState.Service && RDVState.TypeRDV == null &&
                        < Col xs="12" sm="10" lg="8" xl="6">
                            <div className="card mb-2" >

                                <div className="card-header ">
                                    <strong>Choisissez le type de rendez-vous </strong>
                                </div>
                                <div className="card-body mb-2">
                            <ChoixConsultation />
                            <Button variant="secondary" onClick={RetourTypeConsultation} >Précédent</Button>
                                </div>
                            </div>
                        </Col>
                    }
                    {RDVState.Service && RDVState.TypeRDV &&
                        < Col xs="12" sm="10" lg="8" xl="6">
                            <div className="card mb-2" >

                                <div className="card-header ">
                                    <strong>Vos critères de recherche </strong>
                                </div>
                        <div className="card-body mb-2">
                            {loading &&
                                <LoadingSpinner />
                            }
                            {!loading &&
                                <Container fluid style={{ paddingTop: 15 }} >
                                    Sélectionnez un ou plusieurs sites :<br />
                                    <ChoixSite />
                                    <hr />
                                    Sélectionnez un ou plusieurs médecins :<br />
                                    <ChoixMedecin />
                                    <hr />
                                    Sélectionnez une date de rendez-vous :<br />
                                    <DatePicker selected={RDVState.Date} onChange={date => RDVDispatch({ type: 'SET_DATE', payload: date })} dateFormat="dd/MM/yyyy" />
                                    <hr />
                                    Sélectionnez un ou plusieurs jours :<br />
                                    <ChoixJours />
                                    <hr />
                                    <br />
                                <Button variant="secondary" onClick={RetourTypeRdv} >Précédent</Button> &nbsp;
                                <Button variant="primary" onClick={recherche} >Rechercher un rendez-vous</Button>

                                </Container>
                            }
                                </div>
                            </div>
                    </Col>

                    
                    }
                </>
            }
            { resultats &&
                    
                <>
                <Button variant="outline-secondary" onClick={recherchePrecedents} disabled={prevDate == null}>Résultats précédents</Button> <Button variant="outline-primary" disabled={nextDate == null} onClick={rechercheSuivants} >Continuer la recherche de plages libres</Button>
                    <br /><br />
                    {loading &&
                        <LoadingSpinner />
                    }
                    {!loading &&
                        <ChoisirRDV resultats={resultats} annuler={annulerRecherchePlageLibre} />
                    }
                </>
            }
           
            </>
        );

}