import React, { useState } from 'react'
import Moment from 'moment';
import BoutonPdf from './BoutonPdf.js'

function ListeFactures({ data, previewRecords = 5, showSolde = true }) {
    const [showMore, setShowMore] = useState(false)
     
    return (
        <>
            {(!data || data.length <= 0) &&
                <div>Aucune facture </div>

            }
            {(data && data.length > 0) &&
                <div className="table-responsive">
                    <table className="table table-striped table-hover ">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                                <th scope="col">Num</th>
                                <th scope="col">Date</th>
                            <th scope="col" className="text-end">Montant</th>
                            {showSolde &&
                                <th scope="col" className="text-end">
                                    Solde</th>
                            }
                                <th scope="col">Medecin</th>
                                
                            </tr>
                        </thead>
                        <tbody>

                        {data && data.slice(0, showMore ? data.length : previewRecords).map((item) => {
                                var date = Moment(item.DU).format('DD/MM/YYYY');
                                return (
                                    <tr key={item.NFACT}>
                                        <td><BoutonPdf chemin={item.PATH_PDF} numFacture={item.NFACT} /></td>
                                        <td scope="row">{item.NFACT}</td>
                                        <td>{date}</td>
                                        <td className="text-end"><b>{item.MONTANT.toFixed(2)}&nbsp;€</b></td>

                                        {showSolde &&
                                            <td className="text-end">
                                                {item.RESTE_A_PAYER.toFixed(2)}&nbsp;€
                                            </td>
                                        }
                                        <td>{item.MED_RESP}</td>
                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                {!showMore && (data.length> previewRecords) &&
                    <button type="button" onClick={() => setShowMore(true)} className="btn btn-secondary btn-lg" >Afficher tout</button>
                }
                </div>
            }
            </>
    )}

export default ListeFactures;