import React, { useState, useEffect, useRef } from "react";
import { useAuthState, useAuthDispatch } from "../../Context/context.js";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useForm from "../FormValidation/useForm.js";
import InputValidation from "../FormValidation/InputValidation.js";
import InputValidationPassword from "../FormValidation/InputValidationPassword.js";
import InputCheckboxValidation from "../FormValidation/InputCheckboxValidation.js";
import logo from "../logo_my_chrh_144.png";
import * as bootstrap from "bootstrap";
import * as jose from "jose";

export function Enregistrement() {
  const [niss, setNiss] = useState("");
  const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();


  //Recuperer les params querystring
  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };
  const CodeEnregistrement = getParameterByName("Code");

  const [modal, setModal] = useState(null);
  const ShowModal = () => {
    let modalObj = new bootstrap.Modal(document.getElementById("CGModal"), {
      keyboard: false,
    });
    modalObj.show();
    setModal(modalObj);
  };

  const textInputNiss = useRef(null);
  const textInputAlias = useRef(null);
  const regexNiss =
    /^[0-9]{2}[.\-]{0,1}[0-9]{2}[.\-]{0,1}[0-9]{2}[.\-]{0,1}[0-9]{3}[.\-]{0,1}[0-9]{2}$/;
  const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const InitialValues = {
    RegisterByMail: CodeEnregistrement != null,
    Niss: niss,
    Alias: "",
    newPass: "",
    newPass2: "",
    email: "",
    facturesParMail: false,
  };
  const ValidationSchema = yup.object().shape({
    Alias: yup
      .string()
      .trim()
      .required("Obligatoire")
      .min(6, "Minimum 6 caractères")
      .matches(
        /^[A-Za-z0-9_\-@.]{6,}$/,
        "Ne respecte pas les consignes"
      ),
    Niss: yup
      .string()
      .trim()
      .matches(regexNiss, "Format attendu: 00.01.01-123.45")
      .required("Obligatoire"),
    newPass: yup
      .string()
      .trim()
      .required("Obligatoire")
      .min(8, "Minimum 8 caractères")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
        "Doit contenir au moins une majuscule, une minuscule et un chiffre"
      ), // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    newPass2: yup
      .string()
      .trim()
      .required("Obligatoire")
      .oneOf([yup.ref("newPass"), null], "Les mots de passes sont différents"),
    RegisterByMail: yup.boolean(),
    email: yup
      .string()
      .email("Mauvais format de l''email")
      .when("RegisterByMail", {
        is: (value) => value === false,
        then: (schema) => schema.required("Obligatoire"),
        otherwise: (schema) => schema,
      }),
  });

  const saveData = () => {
    debugger;
    const jssha = require("js-sha256");

    function hash(input) {
      return jssha.sha256(input).toUpperCase();
    }

    const hashResult = hash(formValidation.values.newPass);

    var bearerToken = AuthState.Token;
    if (bearerToken != null) {
      let payloadJwt = jose.decodeJwt(bearerToken);
      if (
        payloadJwt.sper != null &&
        payloadJwt.niss != null &&
        (payloadJwt.alias == null || payloadJwt.alias == "")
      ) {
        const valeurs = {
          NewPass: hashResult,
          Alias: formValidation.values.Alias,
          Email: formValidation.values.email,
          FacturesParMail: formValidation.values.facturesParMail,
        };

        //Sauvegarde Itsme
        fetch("donnees/CreerCompteItsMe/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
          },
          body: JSON.stringify(valeurs),
        })
          .then((response) => {
            if (!response.ok) {
              if (response.status === 400) {
                //alert('Erreur pendant la procédure')
                //throw new Error(response.status);
              }

              //throw new Error(response.status);
            }

            return response.json();
          })
          .then((data) => {
            if (data.status == 400) {
              console.log("error: " + data.detail);
              if (data.detail == "Invalid niss") {
                alert("Le numero national n'est pas correcte !");
              } else if (data.detail == "TIMEOUT") {
                alert("Le lien n'est plus valable !");
                history("/login/");
              } else if (data.detail == "NOINSTALLCODE") {
                alert("Le code de création de code n'est pas correct");
                history("/login/");
              } else if (data.detail == "SPEREXISTS") {
                alert("Vous avez déjà un compte de créé !");
                history("/login/");
              } else if (data.detail == "ALIASEXISTS") {
                alert(
                  "Ce nom d'utilisateur existe déjà. Veuillez en choisir un autre..."
                );
              } else {
                alert("Erreur pendant la procédure !");
              }
            } else {
              alert("compte créé avec succès !");
              history("/rendezvous/");
            }
          })
          .catch((error) => {
            console.log("error: " + error);
          });

        return;
      }
    } else {
      const valeurs = {
        Id: getParameterByName("Code"),
        Niss: formValidation.values.Niss,
        NewPass: hashResult,
        Alias: formValidation.values.Alias,
      };
      fetch("donnees/CreerCompte/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(valeurs),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 400) {
              //alert('Erreur pendant la procédure')
              //throw new Error(response.status);
            }

            //throw new Error(response.status);
          }

          return response.json();
        })
        .then((data) => {
          if (data.status == 400) {
            console.log("error: " + data.detail);
            if (data.detail == "Invalid niss") {
              alert("Le numero national n'est pas correcte !");
            } else if (data.detail == "TIMEOUT") {
              alert("Le lien n'est plus valable !");
              history("/login/");
            } else if (data.detail == "NOINSTALLCODE") {
              alert("Le code de création de code n'est pas correct");
              history("/login/");
            } else if (data.detail == "SPEREXISTS") {
              alert("Vous avez déjà un compte de créé !");
              history("/login/");
            } else if (data.detail == "ALIASEXISTS") {
              alert(
                "Ce nom d'utilisateur existe déjà. Veuillez en choisir un autre..."
              );
            } else {
              alert("Erreur pendant la procédure !");
            }
          } else {
            alert("compte créé avec succès !");
            history("/login/");
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  };

  const formValidation = useForm(InitialValues, saveData, ValidationSchema);

  useEffect(() => {
    //Vérifier si on vient de itsme -> token avec sper mais pas de username
    var bearerToken = AuthState.Token;
    if (bearerToken != null) {
      let payloadJwt = jose.decodeJwt(bearerToken);
        if (
            payloadJwt.sper != null &&
            payloadJwt.niss != null &&
            (payloadJwt.alias == null || payloadJwt.alias == "")
        ) {
            setNiss(payloadJwt.niss);
            formValidation.setCtrlValue("Niss", payloadJwt.niss);
            return;
        }
        else {
            //On a un token non valide, il faut le supprimer
            AuthDispatch({ type: 'LOGOUT' });
        }
    }

    //Sinon Vérif si l'id est passé
    var id = getParameterByName("Code"); //Appel depuis le site de l'hopital
    if (!id) {
      alert(
        "Veuillez ouvrir cette page à partir du lien que vous avez recu dans le mail."
      );
      history("/login/");
      return;
    }

    textInputNiss.current.focus();
    if (niss != "") textInputAlias.current.focus();
  }, []);

  const annuler = () => {
    history("/rendezvous");
  };

  const submit = () => {
    saveData();
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div>
          <Container fluid style={{ paddingTop: 15 }}>
            <Row className="justify-content-center">
              <Col xs="12" sm="11" lg="10" xl="9">
                <Container fluid style={{ paddingTop: 15 }}>
                  <Row className="justify-content-center">
                    <Col xs="12" sm="6" className="text-center mb-5">
                      <img src={logo} alt="My chr de Huy" />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs="12" sm="11" lg="10" xl="9">
                      <div id="ListeFactures" className="card mb-2">
                        <div className="card-header ">
                          <strong>Créez votre compte</strong>
                        </div>
                        <div className="card-block mb-2">
                          <Row className="justify-content-center">
                            <form
                              className="needs-validation"
                              noValidate
                              className="col-sm-10"
                            >
                              <div className="row p-2  g-3 align-items-center">
                                <div className="col-lg-4">
                                  <label htmlFor="Niss">
                                    Numéro national :
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <InputValidation
                                    nom="Niss"
                                    validation={formValidation}
                                    placeholder="00.01.01-123.45"
                                    tabIndex="1"
                                    desactive={niss != ""}
                                    reference={textInputNiss}
                                  />
                                </div>
                              </div>
                              <div className="row p-2  g-3 align-items-center">
                                <div className="col-lg-4">
                                  <label htmlFor="Alias">
                                    Nom d'utilisateur :
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <InputValidation
                                    autoFocus
                                    nom="Alias"
                                    validation={formValidation}
                                    tabIndex="2"
                                    reference={textInputAlias}
                                    />
                                    <small className="text-muted">
                                        Min 6 car. (lettres, chiffres, '_' '-' '@' '.')
                                    </small>
                                </div>
                              </div>
                              <div className="row p-2  g-3 align-items-center">
                                <div className="col-lg-4">
                                  <label htmlFor="newPass">
                                    Mot de passe :
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <InputValidationPassword
                                    nom="newPass"
                                    validation={formValidation}
                                    tabIndex="3"
                                  />
                                  <small className="text-muted">
                                    Min 8 car. avec majuscules, minuscules et
                                    chiffres.
                                  </small>
                                </div>
                              </div>
                              <div className="row p-2  g-3 align-items-center">
                                <div className="col-lg-4">
                                  <label htmlFor="newPass2">Confirmez :</label>
                                </div>
                                <div className="col-lg-8">
                                  <InputValidationPassword
                                    nom="newPass2"
                                    validation={formValidation}
                                    tabIndex="4"
                                    placeholder="Répétez votre mot de passe"
                                  />
                                </div>
                              </div>
                              {niss != "" && (
                                <>
                                  <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">
                                      <label htmlFor="email">Email :</label>
                                    </div>
                                    <div className="col-lg-8">
                                      <InputValidation
                                        nom="email"
                                        validation={formValidation}
                                        tabIndex="5"
                                      />
                                    </div>
                                  </div>
                                  <div className="row p-2  g-3 align-items-center">
                                    <div className="col-lg-4">&nbsp;</div>
                                    <div className="col-lg-8">
                                      <InputCheckboxValidation
                                        nom={"facturesParMail"}
                                        validation={formValidation}
                                        desactive={niss == ""}
                                        mb="2"
                                        displaySwitch={true}
                                        texte="Recevoir mes factures par mail"
                                        tabIndex="6"
                                      />
                                      <small
                                        className="text-muted"
                                        onClick={ShowModal}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Voir les conditions générales
                                      </small>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="row p-2  g-3">
                                <div className="col-lg-12  d-flex justify-content-end">
                                  <Button
                                    type="button"
                                    onClick={annuler}
                                    variant="secondary"
                                    tabIndex="7"
                                  >
                                    Plus tard...
                                  </Button>{" "}
                                  &nbsp;&nbsp;
                                  <Button
                                    type="button"
                                    onClick={formValidation.handleSubmit}
                                    tabIndex="8"
                                  >
                                    Créer mon compte
                                  </Button>
                                </div>
                              </div>
                            </form>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div
        className="modal fade"
        id="CGModal"
        tabindex="-1"
        aria-labelledby="CGModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen-lg-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Conditions générales pour utilisation de l'adresse Email
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Le/la patient(e) est responsable du maintien de la
                confidentialité et de la sécurité de son compte de messagerie.
                <br />
                Il/elle s'engage à avertir le C.H.R. de Huy dans les plus brefs
                délais s'il/si elle prend connaissance d'un incident de sécurité
                ou d'une violation concernant son compte y compris s'il/si elle
                pense que son mot de passe pourrait être piraté.
              </p>
              <p>
                Le C.H.R. de Huy décline toute responsabilité en cas de perte,
                de vol ou de piratage de mot de passe, ou concernant toute
                opération exécutée par des utilisateurs non autorisés sur le
                compte du/de la patient(e), ou en cas de dommages résultant
                d'une utilisation non autorisée de son compte ou se rapportant à
                une telle utilisation, s'il/si elle en est à l'origine.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
