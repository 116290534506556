import { useState, useEffect, useCallback } from 'react'

let useForm = (initialValues, callback, validationSchema) => {
    let [values, setValues] = useState(initialValues)
    let [errors, setErrors] = useState([])
    let [isSubmitting, setIsSubmitting] = useState(false)

    const callbackEffect = useCallback(() => {
        setIsSubmitting(false);
        callback()
    }
        , [callback]);

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                callbackEffect()
            }
        },
        [errors, isSubmitting, callbackEffect],
    )




    let handleSubmit = event => {
        if (event)
            event.preventDefault();
        // Only validate if the validate function is used

        let errors = validate(values, validationSchema); 
        setErrors(errors)

        setIsSubmitting(true)
    }

    const setCtrlValue = (controlName, value) => {
        setValues(values => ({
            ...values,
            [controlName]: value,
        }))
    }

    let handleChange = event => {
        event.persist();
        setIsSubmitting(false);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setValues(values => ({
            ...values,
            [name]: value,
        }))
    }

    let handleChangeCheckbox = (event, trueValue, falseValue) => {
        event.persist();
        setIsSubmitting(false); debugger;
        const target = event.target;
        const value = target.checked ? trueValue : falseValue;
        const name = target.name;
        setValues(values => ({
            ...values,
            [name]: value,
        }))
    }

    let resetValues = () => {
        debugger;
        setValues (initialValues);


    }

    function validate(values, validationSchema) {
        try {
            validationSchema.validateSync(values, { abortEarly: false });
        }
        catch (e) {
            console.log('Erreur de validation du formulaire');
            return e.inner;
        }
        return [];
    };

    let getError = input => {
        const inputError = errors.find((ve) => ve.path === input)
            ? errors.find((ve) => ve.path === input).message
            : null;

        return inputError;
    }
    return {
        handleChange,
        handleChangeCheckbox,
        handleSubmit,
        values,
        errors,
        getError,
        resetValues,
        setCtrlValue
    }
}

export default useForm