import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';*/
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import LoadingSpinner from "../LoadingSpinner.js";
import { useNavigate } from "react-router-dom";
import MaPreAdmission from './MaPreAdmission.js'

export function PreAdmissions() {
    const [rdvFuturs, setRdvFuturs] = useState([]);
    const [loading, setLoading] = useState(true);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    useEffect(() => {
        
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            setLoading(true);
            fetch('donnees/PreAdmissions/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    setRdvFuturs(data);

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, []);

    //[] = liste de variable qui declenche une execution de la fonction lorsque que leur valeur change
    //avec une liste vide [], use effect ne s'execute qu'une fois au chargement de la page


    const PrendreRDV = () => {
        history("/nouveau-rendezvous/");
    }

    return (
        <div>
            <h1  >Mes pré-admissions </h1>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&

                <div>
                <Container fluid style={{ paddingTop: 15 }} className="mb-2">
                        <Row >
                            <Col >
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="11" lg="10" xl="9">
                                <Row>

                                    {rdvFuturs != null && rdvFuturs.length > 0 && rdvFuturs.map((item) => {
                                        return (
                                            <MaPreAdmission item={item}  key={item.MRES}/>
                                        )
                                    })}
                                    {(rdvFuturs == null || rdvFuturs.length <= 0) &&
                                        <em>Vous n'avez aucune pré-admission de prévue</em>
                                }
                                
                                </Row>
                            </Col>
                    </Row>
                    </Container>
                </div>
            }
        </div>
    );

}