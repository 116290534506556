import React from 'react'
import { useRDVState, useRDVDispatch } from '../../Context/context.js';
import './NavigationNouveauRDV.css';

function NavigationNouveauRDV({ resultats, setResultats }) {
    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();
    const returnToService = () => {
        if (RDVState.Service != null) {
            returnToTypeRDV();
            RDVDispatch({ type: 'SET_SERVICE', payload: null });
        }
    }

    const returnToTypeRDV = () => {
        if (RDVState.TypeRDV != null) {
            returnToCriteres();
            RDVDispatch({ type: 'SET_TYPERDV', payload: null })
        }

    }

    const returnToCriteres = () => {
        if (resultats != null)
            setResultats(null);
    }



    return (
        <div className="d-none d-lg-block">
            <div className="custombreadcrumb flat">
                <a className="active" onClick={ returnToService } >{RDVState.Service != null ? RDVState.Service.NOMSERVICE : "Service"}</a>
                <a className={` ${RDVState.Service != null ? "active" : ""}`} onClick={returnToTypeRDV} >{RDVState.TypeRDV != null ? RDVState.TypeRDV.NOMTYPERDV : "Consultation"}</a>
                <a className={` ${RDVState.TypeRDV != null ? "active" : ""}`} onClick={returnToCriteres} >Critère de recherche</a>
                <a className={` ${resultats != null ? "active" : ""}`}>Choisir plage libre</a>
            </div>
            <br /><br />
        </div>
    )

}

export default NavigationNouveauRDV