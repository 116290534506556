import moment from 'moment';

let initialStateDB = localStorage.getItem('searchCriteria');


export const RDVState =/* initialStateDB || */ {
	Service: null,
	TypeRDV: null,
	Sites: [],
	Medecins: [],
	MedecinsForTypeRDV:-1,
	Date: moment().add(1, 'days').toDate(),
	Jours: { "1": true, "2": true, "4": true, "8": true, "16": true, "32": true, "64": true },
	TypeRDVUnique: null,
	RechercheEnAvant: true
}

export const RDVReducer = (RDVState, action) => {
	switch (action.type) {
		case 'SET_SERVICE':
			return {
				...RDVState,
				Service: action.payload,
				RechercheEnAvant: true,
			};
		case 'SET_TYPERDV': 
			if (action.payload == null) { //Si on annule depuis
				if (RDVState.TypeRDVUnique != null && RDVState.TypeRDVUnique)
					return {
						...RDVState,
						TypeRDV: null,
						TypeRDVUnique: null,
						Service: null,
						RechercheEnAvant: true
					}
				else {
					return {
						...RDVState,
						TypeRDV: null,
						TypeRDVUnique: null,
						RechercheEnAvant: true
					}
                }
			}
			else {
				return {
					...RDVState,
					TypeRDV: action.payload.TypeRDV,
					TypeRDVUnique: action.payload.TypeRDVUnique,
					Date: moment().add(1, 'days').toDate(),
					Jours: { "1": true, "2": true, "4": true, "8": true, "16": true, "32": true, "64": true },
					RechercheEnAvant: true
				};
			}
		case 'SET_SITES':
			return {
				...RDVState,
				Sites: action.payload,
				RechercheEnAvant: true,
			};
		case 'SET_MEDECINS':
			return {
				...RDVState,
				Medecins: action.payload,
				MedecinsForTypeRDV: RDVState.TypeRDV.IDTYPERDV,
				RechercheEnAvant: true
			};
		case 'SET_DATE':
			return {
				...RDVState,
				Date: action.payload,
				RechercheEnAvant: true
			};
		case 'SET_DATE_RECH_ARRIERE':
			return {
				...RDVState,
				Date: action.payload,
				RechercheEnAvant: false
			};
		case 'SET_JOURS':
			return {
				...RDVState,
				Jours: action.payload,
				RechercheEnAvant: true,
			};
		case 'RESET':
			return {
				Service: null,
				TypeRDV: null,
				Sites: [],
				Medecins: [],
				Date: moment().add(1, 'days').toDate(),
				Jours: { "1": true, "2": true, "4": true, "8": true, "16": true, "32": true, "64": true },
				TypeRDVUnique: null,
				RechercheEnAvant: true
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};