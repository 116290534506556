import React, { useState, useEffect } from 'react'
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { Container, Row, Col, Spinner, Table } from 'react-bootstrap';

import ListeFactures from './ListeFactures.js';
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../LoadingSpinner.js";
import './Factures.css';

export function Factures() {
    const [facturesPayees, setFacturesPayees] = useState([]);
    const [facturesNonPayees, setFacturesNonPayees] = useState([]);

    const [loading, setLoading] = useState(false);
    const [installApp, setInstallApp] = useState(null);

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const GetFacturesPayees = (factures) => {
        if (factures != null && factures.length > 0)
            return factures
                .filter(facture => facture.RESTE_A_PAYER <= 0.05)
                .sort((a, b) => { return (a.DU > b.DU) ? -1 : +1; });
        else
            return [];
    }

    const GetFacturesNonPayees = (factures) => {
        if (factures != null && factures.length > 0)
            return factures
                .filter(facture => facture.RESTE_A_PAYER > 0.05)
                .sort((a, b) => { return (a.DU > b.DU) ? 1 : -1; });
        else
            return [];
    }

    /*
        Faire:
        Mot de passe oublié / utilisateur oublié
        Gestion du profil utilisateur
        Factures non payées à part
        Afficher le nom du patient à coté du logo*/




    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            setLoading(true);


            fetch('donnees/factures/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    //setListeFactures(data);
                    setFacturesPayees(GetFacturesPayees(data));
                    setFacturesNonPayees(GetFacturesNonPayees(data));
                    setLoading(false);

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, []); //S'il n'y avais pas le second parametre [sper], la fonction s'executerais à chaque rafraissiement d'écran
    //Avec le param [sper], elle est executée seulement au chargement: Elle remplace donc componentWillMount
    //En fait [sper] permet de définir une liste de variable qui declenche la fonction quand elles sont modifiées. 
    //Sans cela, c'est warning car la moindre action déclencherais le useEffect


    return (
        <div>
            <h1  >Mes factures</h1>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&
                <div>
                    <Container fluid style={{ paddingTop: 15 }} className="mb-2">
                        {false && installApp &&
                            <Row>
                                <Col>
                                    <button type="button" onClick={() => { alert('Installation en cours'); installApp.prompt(); }} className="btn btn-danger btn-lg mb-5" >Installer l'application</button>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs="12" sm="11" lg="10" xl="9" className="mb-5">
                                <h5 className="mb-2" >Factures non payées</h5>
                                {facturesNonPayees.length <= 0 &&
                                    <div class="alert alert-dark mb-2" role="alert">
                                        Toutes les factures sont payées !
                                    </div>

                                }
                                {facturesNonPayees.length > 0 &&
                                    <p className=" mb-2">
                                        <ListeFactures data={facturesNonPayees} previewRecords={10} />
                                    </p>
                                }
                            </Col>
                        </Row>
                        <Row >
                            <Col xs="12" sm="11" lg="10" xl="9">
                                <div className="accordion" >
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className={`accordion-button  ${facturesNonPayees.length <= 0 ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Factures payées &nbsp;<span className="badge bg-primary rounded-pill">{facturesPayees.length}</span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className={`accordion-collapse collapse   ${facturesNonPayees.length <= 0 ? "show" : ""}`} aria-labelledby="headingTwo" >
                                            <div className="accordion-body">
                                                <Col>
                                                    <ListeFactures data={facturesPayees} showSolde={false} />
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>




    );
}



