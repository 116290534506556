import React, { useRef } from 'react'
import { Button, Card } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from '../FormValidation/useForm.js';
import InputValidation from '../FormValidation/InputValidation';
import InputValidationPassword from '../FormValidation/InputValidationPassword';

export function ReinitPassword({ donnees, niss, sper, affichePannelLogin }) {
    const InitialValues = { NISS: JSON.parse(niss), SPER: JSON.parse(sper) , Password:'', ConfirmPassword:'' }
    const ValidationSchema = yup.object().shape({
        NISS: yup.string()
            .required('Obligatoire'),
        Password: yup.string()
            .trim()
            .required('Obligatoire'),
        ConfirmPassword: yup.string()
            .trim()
            .required('Obligatoire')
            .oneOf([yup.ref("Password"), null], "Les mots de passes sont différents")
    });

    const SetPassword = () => {
        const jssha = require('js-sha256')
        //setLoading(true);
        function hash(input) {
            return jssha.sha256(input).toUpperCase();
        }

        const hashResult = hash(formValidation.values.Password)
        const valeurs = {
            sper: formValidation.values.SPER,
            NISS: formValidation.values.NISS,
            Password: hashResult
        }

        fetch('rdvo/SetPasswordRDV', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(valeurs)
        })
            .then(response => response.json())
            .then((data) => {
                window.open("/rendezvous/?token=" + data.token);
                affichePannelLogin();
            })
            .catch(error => {
                console.log('error: ' + error);
                if (error != null && error.response != null && error.status != null) {
                    if (error.response.status === 403) {

                        //dispatch({ type: 'LOGIN_ERROR' });
                        alert('Erreur lors de la procédure, re-demandez un nouveau mot de passe')
                    }
                    else if (error.response.status === 500) {

                        //dispatch({ type: 'LOGIN_ERROR' });
                        alert('Erreur interne, recommencez la procédure.')
                    }
                }
            });
    }

    const formValidation = useForm(InitialValues, SetPassword, ValidationSchema);

    return (
        <div className="row">
            <div className="col-md-6">
                <Card style={{ height: "100%" }}>
                    <Card.Header>
                        <h3 className="card-title">Veuillez vérifier vos données</h3>
                    </Card.Header>
                    <Card.Body>
                        <p><em>En cas d'erreurs, merci de le signaler lors de votre prochain passage à l'hôpital</em></p>
                        <div className="form-group">
                            <label className="control-label">Votre nom complet</label>
                            <input type="text" disabled title="Votre nom complet" className="form-control" name="rdv_nomComplet" value={donnees != null ? JSON.parse(donnees).NomPrenom : ""}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Votre téléphone ou GSM</label>
                            <input type="text" disabled title="Votre téléphone ou GSM" className="form-control" name="rdv_numTel" value={donnees != null ? JSON.parse(donnees).TelGsm : ""}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Votre adresse email</label>
                            <input type="email" disabled title="Votre adresse email" className="form-control" name="rdv_email" value={donnees != null ? JSON.parse(donnees).Email : ""}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Votre médecin traitant</label>
                            <input type="email" disabled title="Votre médecin traitant" className="form-control" name="rdv_medTrt" value={donnees != null ? JSON.parse(donnees).MedecinF : ""}></input>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-6">
                <Card style={{ height: "100%" }}>
                    <Card.Header>
                        <h3 className="card-title">Création du nouveau mot de passe</h3>
                    </Card.Header>
                    <Card.Body>
                        <form id="rdvInitPassword" method="post" action="">
                            <InputValidation type="hidden" nom="SPER" value={sper} validation={formValidation} />
                            <p><em>Celui-ci vous servira pour vos prochaines visites</em></p>
                            <div className="form-group">
                                <label htmlFor="NISS" className="control-label">Rappel de votre numéro de registre national</label>
                                <InputValidation disabled nom="NISS" value={niss} validation={formValidation} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="control-label">Veuillez introduire votre mot de passe</label>
                                <InputValidationPassword nom="Password" validation={formValidation} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className="control-label">Veuillez confirmer votre mot de passe</label>
                                <InputValidationPassword nom="ConfirmPassword" validation={formValidation} />
                            </div>
                        </form>
                    </Card.Body>
                    <Card.Footer>
                        <Button type="button" className="btn btn-default" onClick={formValidation.handleSubmit}>Confirmer</Button>
                    </Card.Footer>
                </Card>
            </div>
        </div >
    );
}