import React, { useState } from 'react';
import {  Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useAuthState, useAuthDispatch } from '../Context/context.js';
import { useNavigate } from "react-router-dom";
import logo from './logo_my_chrh_144.png';
import "./font-awesome.css";

export function NavMenu() {
    const [collapsed, setCollapsed] = useState(true);

    const [cursor, setCursor] = useState('pointer');

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    const displayName = AuthState.TokenFromMyChrh ? '':(AuthState.Prenom + ' ' + AuthState.Nom);


    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const deconnect = () => {
        if (window.confirm('Etes vous sur de vouloir vous déconnecter ?')) {
            AuthDispatch({ type: 'LOGOUT' });
            if (AuthState.TokenFromMyChrh) {
                window.close();
            }
            else {
                history("/login/");
            }
        }
    }


    return (
        <header>
            {AuthState.Token &&

                <div className="navbar navbar-expand-md navbar-light bg-light  border-bottom box-shadow mb-3" >
                    <Container>
                        <Navbar.Brand tag={Link} to="/"><img src={logo} alt='My chr de Huy' /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" data-bs-toggle="collapse" data-bs-target="#responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {(AuthState.TokenFromMyChrh == null || !AuthState.TokenFromMyChrh) &&
                                <li className="nav-item">
                                    <a className="nav-link text-dark" aria-current="page" href="/factures">Mes factures</a>
                                </li>
                            }
                                <li className="nav-item">
                                    <a className="nav-link text-dark" href="/rendezvous">Mes rendez-vous</a>
                                </li>
                                {(AuthState.TokenFromMyChrh == null || !AuthState.TokenFromMyChrh) &&
                                    <li className="nav-item">
                                        <a className="nav-link text-dark" href="/documents">Mes documents</a>
                                    </li>
                                }

                            <li className="nav-item me-3 me-lg-0 dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa fa-user">  </i><b>&nbsp;{displayName}</b>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {displayName != '' &&
                                        <>
                                            <li>
                                                <div className="dropdown-item disabled"><b>{displayName}</b></div>
                                            </li>
                                        <li >
                                            <a className="dropdown-item" href="/Account/MonProfil" >Mon profil</a>
                                        </li>
                                            <li><hr className="dropdown-divider" /></li>
                                        </>
                                    }
                                    <li >
                                        <a className="dropdown-item" onClick={deconnect} style={{ cursor: cursor }}>Déconnexion</a>
                                    </li>

                                </ul>
                            </li>
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </div>




            }
        </header>
    );
}
