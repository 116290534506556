import React from 'react'
import { Overlay } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InputValidation({ validation, nom, type = "text", desactive = false, prepend = null, append = null, popover = null, reference, placeholderInput = null, ...inputProps }) {
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            validation.handleSubmit();
        }
    }

    return (
        <>
            <div className="input-group">
                {prepend != null &&
                    <div className="input-group-prepend">
                        <span className="input-group-text">{prepend}</span>
                    </div>
                }
                <input name={nom}
                    type={type}
                    className={`form-control shadow-none ${validation.getError(nom) && `is-invalid`}`}
                    onChange={validation.handleChange}
                    value={validation.values[nom]}
                    autoFocus={true}
                    onKeyDown={keyPress}
                    ref={reference}
                    disabled={desactive}
                    {...inputProps}
                />
                {placeholderInput &&
                    <label>{placeholderInput}</label>
                }
                {append != null &&
                    <div className="input-group-append">
                        <span className="input-group-text">{append}</span>
                    </div>
                }
                {popover != null &&
                    popover()
                }
                {validation.getError(nom) && (
                    <small className="invalid-feedback">
                        {validation.getError(nom)}
                    </small>
                )}
            </div>
        </>
    );
}

export default InputValidation;