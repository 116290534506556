import React, { useState, useEffect } from 'react'
import { ToggleButton, ButtonGroup, ListGroup, Col, Row } from 'react-bootstrap';
import { useAuthState, useAuthDispatch, useRDVState, useRDVDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";

function ChoixSite() {
    const [sites, setSites] = useState([]);

    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();

    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    const handleSingleCheck = e => {
        if (sites.length == 1) return;
        RDVDispatch({ type: 'SET_SITES', payload: { ...RDVState.Sites, [e.target.value]: e.target.checked } });

    };

    var bearerToken = AuthState.Token;
    if (bearerToken == null) {
        //Pas de token, on redirige vers le login
        //alert("Vous n'êtes pas connecté")
        AuthDispatch({ type: 'LOGOUT' });
        history("/login/");
        return;
    }

    useEffect(() => {
        fetch("/donnees/getsites/" + RDVState.Service.IDSERVICE + "/" + RDVState.TypeRDV.IDTYPERDV, {
            method: 'get',
            headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
        })
            .then(response => response.json())
            .then((data) => {
                let SitesSelected = [];
                for (var i = 0; i < data.length; i++) {
                    SitesSelected = { ...SitesSelected, [data[i].SBAT + "|" + data[i].DUSBAT]: true };
                }
                RDVDispatch({ type: 'SET_SITES', payload: SitesSelected })
                setSites(data);
            })
    }, [RDVState.Service.IDSERVICE, RDVState.TypeRDV.IDTYPERDV]); 

        
    return (
        <div>
            {sites && sites.map((item) => {
                String.prototype.initCap = function () {
                    return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
                        return m.toUpperCase();
                    });
                };

                return (

                    <div className="form-check" key={item.SBAT + "|" + item.DUSBAT}>
                        <input className="form-check-input" type="checkbox" value={item.SBAT + "|" + item.DUSBAT} checked={RDVState.Sites[item.SBAT + "|" + item.DUSBAT]}
                            onChange={handleSingleCheck} id={`med_${item.SBAT + "|" + item.DUSBAT}`}/>
                        <label className="form-check-label" htmlFor={`med_${item.SBAT + "|" + item.DUSBAT}`}>
                            {item.NOMSBAT.initCap()}
                        </label>
                    </div>
                )
            })}

        </div>

    )

}

export default ChoixSite