import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import logo from '../logo_my_chrh_144.png';
import { ProfilTmpForm } from './ProfilTmpForm.js';

export function ProfilTmp({ DisplayMessage = false }) {
    const [creerProfil, setCreerProfil] = useState(false);

    //Recuperer les params querystring
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    return (

        <div>

            <Container fluid style={{ paddingTop: 15 }} >
                <Row className="justify-content-center">
                    <Col xs="12"  >
                        <Container fluid style={{ paddingTop: 15 }}>
                            <Row className="justify-content-center" >
                                <Col xs="12" sm="6" className="text-center mb-5">
                                    <img src={logo} alt='My chr de Huy' />
                                </Col>
                            </Row>
                            {DisplayMessage && !creerProfil &&
                                <Row className="justify-content-center">
                                    <Col xs="12" sm="11" lg="10" xl="9">
                                        <div class="alert alert-warning" role="alert">
                                            <h4 class="alert-heading">C'est votre première visite au CHR Huy</h4>
                                            <hr />
                                        <p> Etant donné que c'est la première fois que vous vous rendez dans notre établissement,<br />
                                            vous devez créer une fiche signalétique temporaire afin de pouvoir prendre un rendez-vous en ligne.<br />
                                            <p> Les rendez-vous pour les services suivants ne peuvent se prendre que par téléphone :<br /><br />
                                                <ul>
                                                    <li>Service de Dentisterie :  <a href="tel:+3285272733">085/27.27.33</a></li>
                                                    <li>Service d’Imagerie Médical (radiologie, scanner et IRM) : <a href="tel:+3285272731">085/27.27.31</a></li>
                                                    <li>Service de Médecine Nucléaire (scintigraphie, un SPECT-CT ou un breath test) : <a href="tel:+3285272732">085/27.27.32</a></li>
                                                    <li>Service de Gastro-Entérologie et Endoscopie : <a href="tel:+3285277460">085/27.74.60</a></li>
                                                    <li>Service de Psychiatrie : <a href="tel:+3285277161">085/27.71.61</a></li>
                                                    <li>Service Médecine physique / Clinique du sport : <a href="tel:+3285277163">085/27.71.63</a></li>
                                                </ul>
                                            </p>
                                            <p><strong>Vous préférez prendre rendez-vous par téléphone, pour toutes les autres disciplines, le Service des Rendez-vous se tient à votre disposition du lundi au vendredi de 8 à 17 heures au <a href="tel:+3285272727">085/27.27.27</a></strong></p>
                                            Merci de votre compréhension.
                                        </p>
                                    </div>
                                    <Button variant="primary" onClick={() => { setCreerProfil(true); } } >Créer ma fiche signalétique temporaire</Button>
                                    </Col>
                                </Row>
                            }
                            {(!DisplayMessage || creerProfil) &&
                                <ProfilTmpForm ></ProfilTmpForm>
                            }
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>



    );
}



