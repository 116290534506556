import React, { useState, useEffect,useRef } from 'react'
import { ListGroup, ButtonGroup, Form, Col, Row } from 'react-bootstrap';
import { useRDVState, useRDVDispatch, useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";

function ChoixConsultation() {
    const [consultations, setConsultations] = useState([]);
    const [loading, setLoading] = useState(true);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();
    const RDVState = useRDVState();
    const RDVDispatch = useRDVDispatch();

   // const idSrv = useRef(RDVState.Service.IDSERVICE );

    useEffect(() => {
        var bearerToken = AuthState.Token;
        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
            return;
        }

        // Met à jour le titre du document via l’API du navigateur
        fetch("/donnees/getconsultation/" + RDVState.Service.IDSERVICE, {
            method: 'get',
            headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
        })
            .then(response => response.json())
            .then((data) => {
                setConsultations(data);
                setLoading(false);
                if (data.length == 1) {
                    //Sélectionner le record s'il n'y en a que un
                    RDVDispatch({ type: 'SET_TYPERDV', payload: { TypeRDV: data[0], TypeRDVUnique: true } })
                }
            })
    }, [RDVState.Service.IDSERVICE]);  //[] = liste de variable qui declenche une execution de la fonction lorsque que leur valeur change
    //avec une liste vide [], use effect ne s'execute qu'une fois au chargement de la page

    return (


        <div className="mb-3">
            {loading && <p><em>Chargement...</em></p>}
            {!loading &&

                <ListGroup>
                    {consultations && consultations.map((item) => {
                        return (

                            <ListGroup.Item key={item.IDTYPERDV} action onClick={(e) => { RDVDispatch({ type: 'SET_TYPERDV', payload: { TypeRDV: item, TypeRDVUnique: false } }); }}>
                                {item.NOMTYPERDV}
                            </ListGroup.Item>

                            //<ListGroup>
                            //    type="radio"
                            //    id={item.IDTYPERDV}
                            //    label={item.NOMTYPERDV}
                            //    key={item.IDTYPERDV}
                            //    name="TYPERDV"
                            //    onChange={(e) => { RDVDispatch({ type: 'SET_TYPERDV', payload: item }); }}
                            //    checked={RDVState.TypeRDV != null && RDVState.TypeRDV.IDTYPERDV == item.IDTYPERDV }
                            ///>

                            ////<ToggleButton
                            ////    key={item.IDTYPERDV}
                            ////    type="radio"
                            ////    className='btn-secondary'
                            ////    name="radio"
                            ////    value={item.IDTYPERDV}
                            ////    checked={selection === item.IDTYPERDV}
                            ////    onChange={(e) => { setSelection(e.currentTarget.value); SetValue(item); }}
                            ////>
                            ////    {item.NOMTYPERDV}
                            ////</ToggleButton>
                        )
                    })}
                </ListGroup>
            }
        </div>

    )

    //return (
    //    <ListGroup horizontal>



    //        {consultations && consultations.map((item) => {
    //        return (
    //                <ListGroup.Item key={item.idConsultation} action>  {item.nomConsultation}
    //                </ListGroup.Item>
    //            )
    //    })}
    //    </ListGroup>
    //);


}

export default ChoixConsultation