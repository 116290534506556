import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';*/
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import LoadingSpinner from "../LoadingSpinner.js";
import { useNavigate } from "react-router-dom";
import MonRendezvous from './MonRendezvous.js';

export function RendezVous() {
    const [rdvFuturs, setRdvFuturs] = useState([]);
    const [rdvPasses, setRdvPasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const history = useNavigate();

    useEffect(() => {
        
        var tokenURL = getParameterByName('token'); //Appel depuis le site de l'hopital
        if (tokenURL) {
            //Login by url token
            AuthDispatch({ type: 'LOGIN_FROM_MYCHRH', payload: tokenURL });
        }

        var bearerToken = null;//token;
        if (tokenURL)
            bearerToken = tokenURL;
        else
            bearerToken = AuthState.Token;

        if (bearerToken == null) {
            //Pas de token, on redirige vers le login
            //alert("Vous n'êtes pas connecté")
            AuthDispatch({ type: 'LOGOUT' });
            history("/login/");
        }
        else {
            fetch('donnees/rendezvous/', {
                method: 'get',
                headers: new Headers({ Authorization: `Bearer ${bearerToken}` })
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert("La session a expiré.")
                            AuthDispatch({ type: 'LOGOUT' });
                            history("/login/");
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    setRdvFuturs(GetRendezvousFuturs(data));
                    setRdvPasses(GetRendezvousPasses(data));

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, []);

    //[] = liste de variable qui declenche une execution de la fonction lorsque que leur valeur change
    //avec une liste vide [], use effect ne s'execute qu'une fois au chargement de la page


    //Recuperer les params querystring
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const GetRendezvousFuturs = (rendezvous) => {
        if (rendezvous != null && rendezvous.length > 0)
            return rendezvous
                .filter(rendezvous => moment().diff(rendezvous.DTRDV) < 0)
                .sort((a, b) => { return (a.DTRDV > b.DTRDV) ? 1 : -1; });
        else
            return null;
    }

    const GetRendezvousPasses = (rendezvous) => {
        if (rendezvous != null && rendezvous.length > 0)
            return rendezvous
                .filter(rendezvous => moment().diff(rendezvous.DTRDV) >= 0)
                .sort((a, b) => { return (a.DTRDV > b.DTRDV) ? -1 : 1; });
        else
            return null;
    }

    const PrendreRDV = () => {
        history("/nouveau-rendezvous/");
    }

    return (
        <div>
            <h1  >Mes rendez-vous </h1>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&

                <div>
                <Container fluid style={{ paddingTop: 15 }} className="mb-2">
                        <Row >
                            <Col >
                            <button type="button" className="btn btn-primary btn-lg" onClick={PrendreRDV}>Prendre rendez-vous</button>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="11" lg="10" xl="9">
                                <Row>

                                    {rdvFuturs != null && rdvFuturs.length > 0 && rdvFuturs.map((item) => {
                                        return (
                                            <MonRendezvous item={item} isFutur={true} key={item.IDRDV}/>
                                        )
                                    })}
                                    {(rdvFuturs == null || rdvFuturs.length <= 0) &&
                                        <em>Aucun rendez-vous à venir</em>
                                }
                                
                                </Row>
                            </Col>
                    </Row>
                    <br />
                        <Row>
                            <Col xs="12" sm="11" lg="10" xl="9">

                                <div className="accordion" >
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Mes rendez-vous passés &nbsp;<span className="badge bg-primary rounded-pill">{rdvPasses? rdvPasses.length:0}</span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" >
                                            <div className="accordion-body">
                                                <Row>
                                                    {rdvPasses != null && rdvPasses.length > 0 && rdvPasses.map((item) => {
                                                        return (
                                                            <MonRendezvous item={item} isFutur={false} key={item.IDRDV}/>
                                                        )
                                                    })}
                                                    {(rdvPasses == null || rdvPasses.length <= 0) &&
                                                        <em>Aucun rendez-vous passé</em>
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    );

}