import React, { useState, useEffect } from 'react';
import { useAuthState, useAuthDispatch } from '../../Context/context.js';
import { useNavigate } from "react-router-dom";
import * as jose from 'jose';


export function Fas_auth() {
    const AuthState = useAuthState();
    const AuthDispatch = useAuthDispatch();
    const [rendezvous, setRendezVous] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useNavigate();

    const JWTCheckIssString = process.env.REACT_APP_ITSME_JWTCHECKISS; //Doit etre dans les params
    const clientId = process.env.REACT_APP_ITSME_CLIENTID;

    useEffect(() => {
        //Recupérer les parametres de itsme
        let code = getParameterByName('code');
        let scope = getParameterByName('scope');
        let iss = getParameterByName('iss');
        let state = getParameterByName('state');
        let client_id = getParameterByName('client_id');
        if (code != null && scope != null && iss != null && state != null && client_id != null) {
            //Ok, on a bien été appelé par itsme
            if (state != AuthState.ITSME_State) {
                //Le state doit etre celui qu'on a donné
                console.log("Itsme Error: Bad state");
                alert('Problème de connexion itsme: BAD_STATE');
                history("/login");
                return false;
            }
            if (iss != JWTCheckIssString) {
                //Le state doit etre celui qu'on a donné
                console.log("Itsme Error: Bad iss");
                alert('Problème de connexion itsme: BAD_ISS');
                history("/login");
                return false;
            }
            if (client_id != clientId) {
                //Le state doit etre celui qu'on a donné
                console.log("Itsme Error: Bad client_id");
                alert('Problème de connexion itsme: BAD_CLIENTID');
                history("/login");
                return false;
            }

            //Tout semble correct, on donne les infos au serveur pour qu'il communique avec it's me et qu'il nous retourne un token
            const valeurs = {
                auth_code: code,
                scope: scope,
                iss: iss,
                state: state,
                client_id: clientId,
                nonce: AuthState.ITSME_Nonce
            }
            fetch("donnees/LoginItsme/",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(valeurs)
                })
                .then(response => {
                    if (!response.ok) {
                        
                        if (response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            if (AuthState.ITSME_Origine === "CHRH") {//Si on vient du site de l'hopital, on doit créer un profil temp
                                history('/Account/ConfirmProfilTmp');
                            }
                            else {
                                //Sinon, pas de création de profil temp
                                alert("Il est nécessaire que vous soyez déjà venu à l'hôpital pour accéder à votre espace MyCHRH. La fonctionnalité de création de compte sera disponible prochainement.")
                            }
                        }
                        else if (response.status === 401) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert('Trop de tentatives de connexion. Essayez dans 15 min.')
                        }
                        throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((data) => {
                    AuthDispatch({ type: 'LOGIN_SUCCESS', payload: data.token });
                    //SI on a pas de login, on propose de s'enregistrer
                    let payloadJwt = jose.decodeJwt(data.token);
                    if (payloadJwt.alias == null || payloadJwt.alias == '') {
                        history("/Account/register.aspx/");
                    }
                    else {
                        history("/rendezvous/");
                    }
                    //history("/rendezvous/");
                })
                .catch(error => {
                    console.log('error: ' + error);
                    if (error != null && error.response != null && error.status != null) {
                        if (error.response.status === 403) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert('Nom ou mot de passe incorrect')
                        }
                        else if (error.response.status === 401) {

                            //dispatch({ type: 'LOGIN_ERROR' });
                            alert('Trop de tentatives de connexion. Essayez dans 15 min.')
                        }
                    }
                });

        }
        else {
            console.log('probleme de variable itsme');
            console.log('code ' + code);
            console.log('scope ' +scope );
            console.log('iss ' +iss);
            console.log('state ' +state);
            console.log('client_id ' +client_id )
            history("/login");
            return false;
        }

    }, []);

    //Recuperer les params querystring
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    return (
        <>

        </>
    );

}